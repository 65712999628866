/** @jsx jsx */
import { Flex, jsx } from 'theme-ui'
import { observer } from 'mobx-react-lite'
import { FiCameraOff, FiMicOff } from 'react-icons/fi'

export const StreamStateIcons = observer(({ stream }) => {
  return [
    !stream.videoEnabled && (
      <Flex
        key="video-off-icon"
        sx={{
          mr: 1,
          width: '1.5em',
          height: '1.5em',
          alignItems: 'center',
          justifyContent: 'center',
          p: 1,
          borderRadius: 2,
          bg: 'error'
        }}
      >
        <FiCameraOff />
      </Flex>
    ),
    !stream.audioEnabled && (
      <Flex
        key="audio-off-icon"
        sx={{
          mr: 1,
          width: '1.5em',
          height: '1.5em',
          alignItems: 'center',
          justifyContent: 'center',
          p: 1,
          borderRadius: 2,
          bg: 'error'
        }}
      >
        <FiMicOff />
      </Flex>
    )
  ]
})
