import { types } from 'mobx-state-tree'

enum ResizeMode {
  CROP = 'crop-and-scale',
  NONE = 'none'
}

enum CursorConstraint {
  ALWAYS = 'always',
  MOTION = 'motion',
  NEVER = 'never'
}

export const ExactConstraint = types.model('ExactConstraint', {
  exact: types.string
})

export const IdealConstraint = types.model('IdealConstraint', {
  ideal: types.string
})

export const VideoConstraints = types
  .model('VideoConstraints', {
    width: 320,
    height: 240,
    resizeMode: types.optional(
      types.enumeration<ResizeMode>('ResizeMode', Object.values(ResizeMode)),
      ResizeMode.CROP
    ),
    frameRate: 30,
    deviceId: types.maybe(types.union(types.string, ExactConstraint))
  })
  .actions((self) => ({
    setDeviceId(id: string, exact = false) {
      self.deviceId = exact ? { exact: id } : id
    }
  }))

export const DisplayConstraints = VideoConstraints.named(
  'DisplayConstraints'
).props({
  cursor: types.maybe(
    types.array(
      types.enumeration<CursorConstraint>(
        'Cursor',
        Object.values(CursorConstraint)
      )
    )
  )
})

export const AudioConstraints = types
  .model('AudioConstraints', {
    deviceId: types.maybe(types.union(types.string, ExactConstraint))
  })
  .actions((self) => ({
    setDeviceId(id: string, exact = false) {
      self.deviceId = exact ? { exact: id } : id
    }
  }))

export const MediaConstraints = types
  .model('MediaConstraints', {
    video: types.optional(VideoConstraints, {}),
    audio: types.optional(AudioConstraints, {})
  })
  .views((self) => ({
    get audioDeviceId() {
      return self.audio.deviceId
        ? self.audio.deviceId.exact || self.audio.deviceId
        : undefined
    },
    get videoDeviceId() {
      return self.video.deviceId
        ? self.video.deviceId.exact || self.video.deviceId
        : undefined
    }
  }))

export const ScreenConstraints = MediaConstraints.named(
  'ScreenConstraints'
).props({
  video: types.optional(DisplayConstraints, {
    width: 1920,
    height: 1080,
    resizeMode: ResizeMode.NONE,
    cursor: [CursorConstraint.ALWAYS, CursorConstraint.MOTION]
  })
})
