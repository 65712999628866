/** @jsx jsx */
import * as React from 'react'
import { observer } from 'mobx-react-lite'
import { jsx, Grid, Input, Text } from 'theme-ui'

import { useStore } from '@stores/useStore'
import { Toggle } from '@components/Toggle'
import { CustomListBox } from '@components/CustomListBox'
import UserNameForm from '@components/forms/UserNameForm'

export const Settings = observer(() => {
  const room = useStore()
  const { settings, user } = room

  return (
    <Grid sx={{ gridRowGap: 3 }}>
      <UserNameForm />

      <Text variant="caps">Room settings</Text>
      <Toggle
        label="Locked room"
        checked={settings.locked}
        onChange={settings.setLocked}
      />
      <Toggle
        label="Room requires video and/or audio"
        checked={settings.requiresMedia}
        onChange={settings.setRequiresMedia}
      />
      <Grid
        as="label"
        sx={{
          gridTemplateColumns: 'auto 4em',
          gridColumnGap: 2,
          alignItems: 'center'
        }}
      >
        <span>Max. room capacity</span>
        <Input
          type="number"
          value={settings.maxPeers.toString()}
          min={room.totalUsers}
          max={10}
          onChange={(ev) => settings.setMaxPeers(parseInt(ev.target.value, 10))}
        />
      </Grid>
      <Text variant="caps">Device settings</Text>
      {room.declinedPermissions ? (
        <Text>
          No permissions to get audio or video devices. Reload the page to
          request the required permissions again.
        </Text>
      ) : (
        <React.Fragment>
          <CustomListBox
            label="Choose your video input"
            id="video-device"
            value={user.stream.currentVideoDevice}
            onChange={(value) => {
              try {
                user.stream.setVideoDevice(value)
              } catch (e) {
                console.log(e)
              }
            }}
            options={user.stream.videoDevices}
            valueKey="deviceId"
            genericLabel="Video device"
          />
          <CustomListBox
            label="Choose a microphone"
            id="audio-device"
            value={user.stream.currentAudioDevice}
            onChange={(value) => {
              try {
                user.stream.setAudioDevice(value)
              } catch (e) {
                console.log(e)
              }
            }}
            options={user.stream.audioDevices}
            valueKey="deviceId"
            genericLabel="Audio device"
          />
        </React.Fragment>
      )}
      <Toggle
        label="Mirror own webcam?"
        checked={user.mirroredStream}
        onChange={user.setMirroredStream}
      />
    </Grid>
  )
})
