import { SignalData } from 'simple-peer'

export class FriendlyWebSocket {
  reconnectTimer?: number
  url: string
  socket?: WebSocket
  autoReconnect: boolean
  reconnectAttempts = 0
  maxReconnectAttempts: number
  _listeners: { [key: string]: Set<(data?: unknown) => void> }

  constructor(
    url: string,
    options?: { autoReconnect?: boolean; maxReconnectAttempts?: number }
  ) {
    this.url = url
    this.autoReconnect = (options && options.autoReconnect) || false
    this.maxReconnectAttempts = (options && options.maxReconnectAttempts) || 3
    this._listeners = {
      message: new Set(),
      open: new Set(),
      close: new Set()
    }
    this.connect()
  }

  connect() {
    console.log('start connecting')
    this.socket = new WebSocket(this.url)
    this.socket.addEventListener('open', this._open)
    this.socket.addEventListener('close', this._close)
    this.socket.addEventListener('message', this._message)
  }

  _open = () => {
    this._emit('open')
  }

  _message = (event: MessageEvent) => {
    try {
      const json = JSON.parse(event.data)
      if (json.type === 'ping') {
        if (this.socket?.readyState === WebSocket.OPEN) {
          this.socket.send('pong')
        }
      } else {
        this._emit('message', json)
      }
    } catch (e) {
      console.warn('Received invalid JSON message.', event.data)
    }
  }

  _close = () => {
    if (this.autoReconnect) {
      clearTimeout(this.reconnectTimer)
      if (this.reconnectAttempts < this.maxReconnectAttempts) {
        this.reconnectAttempts++
        console.log('Trying to reconnect', this.reconnectAttempts)
        this.reconnectTimer = setTimeout(() => this.connect(), 2000)
      } else {
        this._emit('close')
        console.error(
          `Failed to reconnect after ${this.maxReconnectAttempts} attempts`
        )
        this.disconnect()
      }
    } else {
      this._emit('close')
      this.disconnect()
    }
  }

  _emit(type: string, data?: any) {
    this._listeners[type].forEach((handler) => {
      try {
        handler(data)
      } catch (e) {
        console.warn('Error in message handler', e)
      }
    })
  }

  on(type: string, handler: (data?: any) => void) {
    if (type in this._listeners) {
      this._listeners[type].add(handler)
    }
  }

  off(type: string, handler: (data?: any) => void) {
    if (type === 'message') {
      this._listeners[type].delete(handler)
    }
  }

  send(
    type: string,
    message?:
      | string
      | ArrayBufferLike
      | Blob
      | ArrayBufferView
      | { signal: SignalData; id: string }
      | { [key: string]: any }
  ) {
    if (this.socket?.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify({ type, data: message }))
    } else {
      console.warn('Socket not available.')
    }
  }

  disconnect() {
    console.log('disconnecting the socket')
    clearTimeout(this.reconnectTimer)
    if (this.socket) {
      this.socket.removeEventListener('open', this._open)
      this.socket.removeEventListener('close', this._close)
      this.socket.removeEventListener('message', this._message)
      this.socket.close()
    }
    Object.keys(this._listeners).forEach((key) => {
      this._listeners[key].clear()
    })
  }
}
