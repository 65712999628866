/** @jsx jsx */
import * as React from 'react'
import { jsx } from 'theme-ui'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'
import { animated, useSpring, SpringValue, ControllerProps } from 'react-spring'
import {
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  useTabsContext,
  TabsProps,
  TabProps,
  TabListProps,
  TabPanelProps,
  TabPanelsProps
} from '@reach/tabs'
import '@reach/tabs/styles.css'

const SegmentContext = React.createContext<{
  x: SpringValue<number>
  width: SpringValue<number>
  set: (
    props: ControllerProps<{
      x: number
      width: number
    }>
  ) => void
} | null>(null)

export const SegmentControl = (props: TabsProps) => {
  const [{ x, width }, set] = useSpring(
    () => ({
      x: 0,
      width: 0,
      opacity: 0,
      config: { tension: 280, friction: 28 }
    }),
    []
  )

  return (
    <SegmentContext.Provider value={{ x, width, set }}>
      <Tabs {...props} />
    </SegmentContext.Provider>
  )
}

const SelectedBox = ({ values }) => (
  <animated.div
    sx={{
      position: 'absolute',
      borderRadius: (theme) => `calc(${theme.radii[4]}px - 2px)`,
      bg: 'pink',
      height: 'calc(100% - 4px)',
      top: '2px'
    }}
    style={values}
  />
)

export const SegmentButtonList = ({ children, ...rest }: TabListProps) => {
  const { x, width } = React.useContext(SegmentContext)
  return (
    <TabList
      sx={{
        position: 'relative',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        bg: 'grays.400',
        borderRadius: 4
      }}
      {...rest}
    >
      <SelectedBox values={{ x, width }} />
      {children}
    </TabList>
  )
}

export const SegmentButton = ({
  index,
  ...props
}: TabProps & { index: number }) => {
  const { selectedIndex } = useTabsContext()
  const isSelected = selectedIndex === index
  const [measureRef, rect] = useMeasure({ polyfill: ResizeObserver })
  const { set } = React.useContext(SegmentContext)

  React.useEffect(() => {
    if (isSelected && set) {
      set({
        width: rect.width - 4,
        x: 2 + index * rect.width,
        opacity: 1
      })
    }
  }, [isSelected, rect, set])

  return (
    <Tab
      ref={measureRef}
      {...props}
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        px: 3,
        py: 2,
        transition: 'color 0.3s ease',
        color: isSelected ? 'white' : 'grays.200',
        fontWeight: 'heading',
        borderRadius: 4,
        '&[data-reach-tab][data-selected]': { border: 'none' }
      }}
    />
  )
}

export const SegmentPanels = (props: TabPanelsProps) => (
  <TabPanels sx={{ height: '100%' }} {...props} />
)
export const SegmentPanel = (props: TabPanelProps) => (
  <TabPanel sx={{ height: '100%' }} {...props} />
)
