/** @jsx jsx */
import * as React from 'react'
import { jsx } from 'theme-ui'
import { useTooltip, TooltipPopup, Position } from '@reach/tooltip'
import { useTransition, animated } from 'react-spring'
import '@reach/tooltip/styles.css'

export const AnimatedTooltipPopup = animated(TooltipPopup)

export const positionCentered: Position = (triggerRect, tooltipRect) => {
  if (!triggerRect || !tooltipRect) {
    return {}
  }
  const triggerCenter = triggerRect.left + triggerRect.width / 2
  const left = triggerCenter - tooltipRect.width / 2
  const maxLeft = window.innerWidth - tooltipRect.width - 2
  return {
    left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
    top: triggerRect.top - triggerRect.height + window.scrollY
  }
}

export const Tooltip = ({ children, ...rest }) => {
  const [trigger, tooltip, isVisible] = useTooltip()

  const transition = useTransition(isVisible, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { mass: 1, tension: 500, friction: 40 }
  })

  return (
    <React.Fragment>
      {React.cloneElement(children, trigger)}
      {transition(
        ({ opacity }, item) =>
          item && (
            <AnimatedTooltipPopup
              {...tooltip}
              {...rest}
              sx={{
                bg: 'black',
                color: 'white',
                border: (theme) => `1px solid ${theme.colors.border}`,
                borderRadius: 2,
                px: 3,
                py: 2
              }}
              style={{
                opacity
              }}
              position={positionCentered}
            />
          )
      )}
    </React.Fragment>
  )
}
