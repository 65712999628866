/** @jsx jsx */
import { Box, Heading, Text, jsx } from 'theme-ui'
import { Link, RouteComponentProps } from '@reach/router'

import { Layout } from '@components/Layout'
import { SEO } from '@components/SEO'

export const NotFound = (props: RouteComponentProps) => (
  <Layout>
    <SEO title="Not found" />
    <Box>
      <Heading>Page not found…</Heading>
      <Text>Could not find this page.</Text>
      <Link to="/">Go back home</Link>
    </Box>
  </Layout>
)
