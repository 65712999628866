import { types, getEnv, getRoot } from 'mobx-state-tree'

import { RoomEnv } from '@stores'
import { RoomInstance } from '@stores/models/Room'

export const RequestToJoin = types
  .model('RequestToJoin', {
    id: types.string,
    name: types.string
  })
  .actions((self) => {
    return {
      grant() {
        const env = getEnv<RoomEnv>(self)
        const room = getRoot<RoomInstance>(self)
        env.socket.send('allowAccess', {
          id: self.id,
          roomId: room.id,
          granterId: room.user.id
        })
      },
      decline() {
        const env = getEnv<RoomEnv>(self)
        const room = getRoot<RoomInstance>(self)
        env.socket.send('declineAccess', {
          id: self.id,
          roomId: room.id,
          declinerId: room.user.id
        })
      },
      hold() {
        const env = getEnv<RoomEnv>(self)
        const room = getRoot<RoomInstance>(self)
        env.socket.send('holdAccess', {
          id: self.id,
          roomId: room.id,
          holderId: room.user.id
        })
      }
    }
  })
