/** @jsx jsx */
import { jsx } from 'theme-ui'
import { observer } from 'mobx-react-lite'
import { useStore } from '@stores/useStore'
import { useTransition, animated } from 'react-spring'

export const UnreadMessageCounter = observer((props) => {
  const { unreadMessageCount } = useStore()

  const transition = useTransition(unreadMessageCount !== 0, {
    from: { opacity: 0, scale: 0.5 },
    enter: { opacity: 1, scale: 1 },
    leave: { opacity: 0, scale: 0.5 },
    config: { tension: 320, friction: 24 }
  })

  return transition(
    (values, item) =>
      item && (
        <animated.div
          sx={{
            display: 'flex',
            fontWeight: 'body',
            width: '1.5em',
            height: '1.5em',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            bg: 'black',
            borderRadius: '2em'
          }}
          {...props}
          style={values}
        >
          {unreadMessageCount}
        </animated.div>
      )
  )
})
