/** @jsx jsx */
import * as React from 'react'
import { Grid, jsx, Close } from 'theme-ui'
import { observer } from 'mobx-react-lite'
import Portal from '@reach/portal'
import { animated, useSpring } from 'react-spring'
import { alpha } from '@theme-ui/color'

import { useStore } from '@stores/useStore'
import Panel from '@components/Panel'
import MessageListView from '@components/MessageListView'
import { MessageInputForm } from '@components/forms/MessageInputForm'
import { Settings } from '@components/Settings'
import { Button } from '@components/Button'
import { useBreakpointIndex } from '@theme-ui/match-media'
import { UnreadMessageCounter } from '@components/UnreadMessageCounter'

import {
  SegmentControl,
  SegmentPanels,
  SegmentPanel
} from '@components/SegmentControl'

const Chat = observer(() => {
  const room = useStore()
  const isSmall = useBreakpointIndex() === 0
  const [open, setOpen] = React.useState(false)
  const [showToast, setShowToast] = React.useState(false)
  const [hasScrolled, setHasScrolled] = React.useState(false)
  const inputRef = React.useRef<HTMLInputElement>(null)
  const scrollRef = React.useRef<HTMLDivElement>(null)
  const scrollMargin = 30

  const { x } = useSpring({ x: open ? '0%' : '100%' })

  const updateScroll = () => {
    if (scrollRef.current) {
      scrollRef.current.scroll({
        top: scrollRef.current.scrollHeight,
        left: 0,
        behavior: 'smooth'
      })
      setHasScrolled(false)
      setShowToast(false)
    }
  }

  React.useEffect(() => {
    if (scrollRef.current) {
      if (!hasScrolled) {
        updateScroll()
      } else {
        setShowToast(true)
      }
    }
  }, [room.messages.length])

  const handleScroll = (ev: WheelEvent) => {
    if (!ev.target) return
    const isAtBottom =
      ev.target.scrollTop >
      ev.target.scrollHeight - ev.target.clientHeight - scrollMargin
    if (!hasScrolled && !isAtBottom) {
      setHasScrolled(true)
    } else if (hasScrolled && isAtBottom) {
      setShowToast(false)
      setHasScrolled(false)
    }
  }

  return isSmall ? (
    <SegmentControl>
      <Panel focusRef={inputRef} onClose={updateScroll}>
        <SegmentPanels>
          <SegmentPanel>
            <Grid
              gap={0}
              sx={{
                height: '100%',
                gridTemplateRows: '1fr max-content'
              }}
            >
              <MessageListView
                ref={scrollRef}
                handleScroll={handleScroll}
                messages={room.groupedMessages}
              />

              {showToast && (
                <button
                  sx={{
                    appearance: 'none',
                    border: 'none',
                    position: 'absolute',
                    bottom: '5.5em',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    color: 'grays.100',
                    bg: 'grays.600',
                    borderRadius: '2em',
                    px: 3,
                    py: 2,
                    fontSize: 1,
                    fontFamily: 'inherit',
                    boxShadow: '0 2px 10px black',
                    '&:hover': {
                      cursor: 'pointer'
                    }
                  }}
                  onClick={updateScroll}
                >
                  New messages ↓
                </button>
              )}

              <MessageInputForm
                inputRef={inputRef}
                handleSubmit={room.sendMessage}
                updateScroll={updateScroll}
              />
            </Grid>
          </SegmentPanel>
          <SegmentPanel>
            <Grid
              sx={{
                px: 2,
                pt: (theme) => `calc(var(--handle-size) + ${theme.space[3]}px)`,
                overflow: 'auto',
                webkitOverflowScrolling: 'touch',
                gridTemplateRows: 'auto',
                columnGap: 0,
                rowGap: 0
              }}
            >
              <Settings />
            </Grid>
          </SegmentPanel>
        </SegmentPanels>
      </Panel>
    </SegmentControl>
  ) : (
    <React.Fragment>
      <Button
        onClick={() => {
          setOpen(true)
          if (inputRef.current) inputRef.current.focus()
        }}
        sx={{
          position: 'fixed',
          top: 3,
          right: 3,
          minWidth: '7.5em',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <UnreadMessageCounter sx={{ position: 'absolute', left: 2 }} />
        Chat
      </Button>
      <Portal>
        <animated.div
          sx={{
            position: 'fixed',
            width: 360,
            height: '100%',
            top: 0,
            right: 0,
            bg: 'grays.600',
            overflow: 'hidden',
            borderLeftWidth: '1px',
            borderLeftStyle: 'solid',
            borderLeftColor: alpha('white', 0.1),
            boxShadow: '0 0 10px rgba(0,0,0,0.5)'
          }}
          style={{ x }}
        >
          <Close
            onClick={() => setOpen(false)}
            sx={{ position: 'absolute', top: 3, right: 3 }}
          />
          <Grid
            gap={0}
            sx={{
              height: '100%',
              gridTemplateRows: '1fr max-content'
            }}
          >
            <MessageListView
              ref={scrollRef}
              handleScroll={handleScroll}
              messages={room.groupedMessages}
            />

            {showToast && (
              <button
                sx={{
                  appearance: 'none',
                  border: 'none',
                  position: 'absolute',
                  bottom: '5.5em',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  color: 'grays.100',
                  bg: 'grays.600',
                  borderRadius: '2em',
                  px: 3,
                  py: 2,
                  fontSize: 1,
                  fontFamily: 'inherit',
                  boxShadow: '0 2px 10px black',
                  '&:hover': {
                    cursor: 'pointer'
                  }
                }}
                onClick={updateScroll}
              >
                New messages ↓
              </button>
            )}

            <MessageInputForm
              inputRef={inputRef}
              handleSubmit={room.sendMessage}
              updateScroll={updateScroll}
            />
          </Grid>
        </animated.div>
      </Portal>
    </React.Fragment>
  )
})

export default Chat
