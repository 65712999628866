/** @jsx jsx */
import { Grid, jsx } from 'theme-ui'
import { observer } from 'mobx-react-lite'
import { Layout } from '@components/Layout'
import { SEO } from '@components/SEO'

export const RoomFrame = observer(
  ({ id, children }: { id?: string; children: React.ReactNode }) => (
    <Layout>
      {!!id && <SEO title={id} />}
      <Grid
        sx={{
          justifyContent: 'center',
          gridRowGap: 6,
          pb: 6,
          minHeight: '100vh',
          alignItems: 'center'
        }}
      >
        <Grid
          sx={{
            bg: 'grays.600',
            p: 3,
            borderBottom: (theme) => `3px solid ${theme.colors.grays[400]}`,
            maxWidth: '28em',
            mx: 2
          }}
        >
          {children}
        </Grid>
      </Grid>
    </Layout>
  )
)
