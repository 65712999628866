/** @jsx jsx */
import { jsx, Text, Label } from 'theme-ui'
import { observer } from 'mobx-react-lite'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'

import { useStore } from '@stores/useStore'
import { Button } from '@components/Button'
import { Input } from '@components/forms/Input'

const nameSchema = yup.object().shape({
  name: yup.string()
})

const JoinRoomForm = observer(() => {
  const room = useStore()
  const { user } = room
  return (
    <Formik
      initialValues={{ name: user.name }}
      validationSchema={nameSchema}
      initialStatus={{}}
      onSubmit={async (values, { setStatus }) => {
        user.setName(values.name.trim())
        try {
          console.log('call join form')
          await room.join()
        } catch (e) {
          setStatus({
            error:
              'Could not get permission for required video and or audio. Please reload to ask for permissions again.'
          })
        }
      }}
    >
      {({ isSubmitting, status }) => (
        <Form
          sx={{
            display: 'grid',
            gridRowGap: 2
          }}
        >
          {status.error && <Text>{status.error}</Text>}
          <Label htmlFor="name">Enter your name</Label>
          <Field
            name="name"
            autoComplete="off"
            spellCheck="false"
            component={Input}
          />
          <Button
            type="submit"
            disabled={status.error || isSubmitting}
            sx={{ justifySelf: 'center' }}
          >
            {room.exists
              ? room.settings.locked
                ? 'Request to join'
                : 'Join'
              : 'Create'}
          </Button>
        </Form>
      )}
    </Formik>
  )
})

export default JoinRoomForm
