/** @jsx jsx */
import { jsx } from 'theme-ui'
import * as React from 'react'
import { observer } from 'mobx-react-lite'
import VisuallyHidden from '@reach/visually-hidden'
import { useId } from '@reach/auto-id'
import {
  ListboxOption,
  ListboxInput,
  ListboxButton,
  ListboxPopover,
  ListboxList
} from '@reach/listbox'
import '@reach/listbox/styles.css'

export const CustomListBox = observer(
  ({
    options = [],
    label,
    genericLabel,
    value,
    onChange,
    id,
    valueKey,
    ...rest
  }: {
    label: string
    genericLabel: string
    value: string
    onChange: (value: string) => void
    id: string
    valueKey?: string
    options: {
      label: string
      [key: string]: any
    }[]
  }) => {
    const labelId = `${id}-${useId()}`
    return (
      <React.Fragment>
        <VisuallyHidden id={labelId}>{label}</VisuallyHidden>
        <ListboxInput
          aria-labelledby={labelId}
          value={value}
          onChange={onChange}
          sx={{
            '[data-reach-listbox-button]': {
              color: 'text.body',
              bg: 'grays.500',
              borderRadius: 4,
              py: 2,
              px: 3,
              width: '100%',
              border: (theme) => `1px solid ${theme.colors.border}`,
              '&[aria-expanded="true"]': {
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0
              }
            }
          }}
          {...rest}
        >
          <ListboxButton arrow="▼">
            {({ label: currentLabel }) => <span>{currentLabel}</span>}
          </ListboxButton>
          <ListboxPopover
            sx={{
              '&[data-reach-listbox-popover]': {
                bg: 'grays.500',
                overflow: 'hidden',
                borderBottomLeftRadius: 4,
                borderBottomRightRadius: 4,
                border: (theme) => `1px solid ${theme.colors.border}`
              }
            }}
          >
            <ListboxList
              sx={{
                '& > [data-reach-listbox-option][data-current]': {
                  bg: 'grays.400'
                },
                '& > [data-reach-listbox-option][aria-selected="true"]': {
                  bg: 'pink'
                }
              }}
            >
              {options.map((option, i) => (
                <ListboxOption
                  value={valueKey ? option[valueKey] : option.value}
                  key={`option-${i}`}
                >
                  {option.label || `${genericLabel} #${i}`}
                </ListboxOption>
              ))}
            </ListboxList>
          </ListboxPopover>
        </ListboxInput>
      </React.Fragment>
    )
  }
)
