/** @jsx jsx */
import * as React from 'react'
import { jsx, Text, Flex, AspectRatio } from 'theme-ui'
import { animated, useSpring } from 'react-spring'
import { observer } from 'mobx-react-lite'
import { alpha } from '@theme-ui/color'
import { FiVolume2, FiVolumeX } from 'react-icons/fi'
import { autorun } from 'mobx'

import { PeerInstance } from '@stores/models/Peer'
import { RoundButton } from '@components/Button'
import VolumeIndicator from '@components/VolumeIndicator'
import { StreamStateIcons } from '@components/StreamStateIcons'

interface PeerVideoProps {
  peer: PeerInstance
  position: {
    top: number
    left: number
    width: number
    height: number
  }
}

export const PeerVideo = observer<PeerVideoProps>(function PeerVideo({
  peer,
  position
}) {
  const videoRef = React.useRef<HTMLVideoElement>(null)
  const size = peer.getSize(position)
  const { x, y, width, height } = useSpring({
    width: size.width,
    height: size.height,
    x: position.left + position.width * 0.5 - size.width * 0.5,
    y: position.top + position.height * 0.5 - size.height * 0.5,
    config: { tension: 280, friction: 28 }
  })

  React.useEffect(
    () =>
      autorun(() => {
        if (peer.hasStream && videoRef.current)
          videoRef.current.srcObject = peer.stream.stream
      }),
    []
  )

  return (
    <animated.div
      sx={{
        bg: 'grays.500',
        position: 'absolute',
        width: size.width,
        height: size.height,
        userSelect: 'none',
        maxHeight: '100%',
        '&:hover': {
          '.name': {
            opacity: 1
          }
        }
      }}
      style={{ x, y, width, height }}
    >
      {peer.hasStream ? (
        <video
          sx={{
            display: 'block',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            opacity: peer.stream.videoEnabled ? 1 : 0
          }}
          playsInline
          muted={peer.muted}
          autoPlay
          ref={videoRef}
          className="peerVideo"
        />
      ) : (
        <AspectRatio
          className="peerVideo"
          ratio={peer.stream.aspectRatio}
          sx={{ width: '100%' }}
        />
      )}

      {peer.disconnecting && (
        <Flex
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            bg: alpha('black', 0.8),
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            textAlign: 'center'
          }}
        >
          User is having connection issues. Waiting to reconnect…
        </Flex>
      )}

      {peer.hasStream && !peer.stream.videoEnabled && peer.stream.audioEnabled && (
        <VolumeIndicator
          stream={peer.stream.stream}
          enabled={peer.stream.audioEnabled}
          sx={{
            position: 'absolute',
            bottom: 2,
            right: 2
          }}
        />
      )}

      <Flex sx={{ position: 'absolute', top: 2, left: 2 }}>
        <StreamStateIcons stream={peer.stream} />
      </Flex>

      <RoundButton
        onClick={peer.toggleMuted}
        sx={{ position: 'absolute', top: 2, right: 2 }}
      >
        {peer.muted ? <FiVolumeX /> : <FiVolume2 />}
      </RoundButton>

      <Text
        className="name"
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          py: 1,
          px: 2,
          bg: alpha('black', 0.5),
          color: 'white',
          opacity: 0
        }}
      >
        {peer.name}
      </Text>
    </animated.div>
  )
})
