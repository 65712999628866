/** @jsx jsx */
import * as React from 'react'
import { Formik, Field, Form } from 'formik'
import { navigate } from 'gatsby'
import { jsx, Box, Grid } from 'theme-ui'
import * as yup from 'yup'
import { animated, useSpring } from 'react-spring'
import VisuallyHidden from '@reach/visually-hidden'

import slugify from '@utils/slugify'
import { RoundButton } from '@components/Button'
import { Input } from '@components/forms/Input'
import HRID from '@utils/hrid'

const AnimatedButton = animated(RoundButton)

const initialValues = { name: '' }

const schema = yup.object().shape({
  name: yup.string()
})

export const generateRandomName = HRID({ maxWordLength: 7 })

const RoomNameForm = () => {
  const [randomName, setRandomName] = React.useState(() => generateRandomName())
  const [counter, setCounter] = React.useState(0)
  const [{ degrees }, set] = useSpring(
    () => ({
      degrees: 0,
      config: { tension: 360 }
    }),
    []
  )

  const getNewRandomName = () => {
    setCounter((c) => {
      set({ degrees: (c + 1) * 360 })
      return (c += 1)
    })
    setRandomName(generateRandomName())
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values) => {
        const slug = values.name ? values.name : randomName
        navigate(`/${slug}`)
      }}
    >
      {({ isSubmitting }) => (
        <Form
          sx={{
            display: 'grid',
            justifyContent: 'center',
            gridRowGap: 3,
            textAlign: 'center'
          }}
        >
          <Field name="name">
            {({ field, form }) => (
              <Box sx={{ position: 'relative' }}>
                <Input
                  {...field}
                  placeholder={randomName}
                  maxLength={24}
                  onChange={(ev) => {
                    form.setFieldValue(field.name, slugify(ev.target.value))
                  }}
                  sx={{
                    bg: 'white',
                    border: 'none',
                    borderRadius: '2em',
                    py: '1em',
                    px: '1.2em',
                    width: '100%'
                  }}
                />
                {!field.value && (
                  <AnimatedButton
                    type="button"
                    size="2em"
                    onClick={getNewRandomName}
                    sx={{
                      position: 'absolute',
                      right: '0.8em',
                      top: '0.7em',
                      p: 0,
                      bg: 'transparent',
                      svg: { fill: 'text.subtle' },
                      transformOrigin: 'center center',
                      '&:hover': {
                        bg: 'transparent',
                        color: 'text.subtle'
                      }
                    }}
                    style={{ transform: degrees.to((d) => `rotate(${d}deg)`) }}
                    onMouseEnter={() => {
                      set({ degrees: counter * 360 + 36 })
                    }}
                    onMouseLeave={() => {
                      set({ degrees: counter * 360 })
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 19.276c4.013 0 7.28-3.253 7.273-7.273H17.71c.007 3.153-2.557 5.71-5.71 5.71a5.697 5.697 0 01-5.71-5.71 5.714 5.714 0 015.255-5.696v3.565l4.22-4.219-4.22-4.218v3.31a7.267 7.267 0 00-6.818 7.258A7.26 7.26 0 0012 19.276z" />
                    </svg>
                    <VisuallyHidden>Regenerate random room name</VisuallyHidden>
                  </AnimatedButton>
                )}
              </Box>
            )}
          </Field>

          <RoundButton
            type="submit"
            size="5em"
            disabled={isSubmitting}
            sx={{
              m: '0 auto',
              bg: 'black',
              svg: {
                fill: 'white'
              },
              '&:hover': { bg: 'black', svg: { fill: 'pink' } }
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="58"
              height="17"
              viewBox="0 0 58 17"
            >
              <path d="M8.434 15.844c6.08 0 8.22-1.34 8.74-3.94l.16-.8c.54-2.7-2.5-2.96-3.12-2.96h-3l.06-.3h3c2.32 0 4.12-1.68 4.12-3.82 0-1.88-1.84-3.22-7.32-3.22h-4.02c-3.48 0-4.52 1.24-4.84 2.74l-2.04 9.56c-.38 1.8.62 2.74 3.68 2.74h4.58zm.62-9.12h-.56l.62-2.92h.94c.76 0 1.08.26 1.02.58l-.28 1.3c-.16.76-.44 1.04-1.74 1.04zm-.74 6.2h-1.14l.74-3.48h.76c1.32 0 1.46.2 1.28 1.04l-.4 1.88c-.06.32-.52.56-1.24.56zm13.24 3.22c.6 0 1.5-.06 2.36-.44 1.46-.64 1.58-1.28 1.74-2.06l2.26-10.6c.16-.78.3-1.4-.84-2.04-.72-.4-1.54-.46-2.2-.46-.9 0-1.64.1-2.34.42-1.4.62-1.62 1.3-1.78 2.08l-2.26 10.6c-.16.78-.24 1.48.9 2.08.58.3 1.34.42 2.16.42zm19.04 0c1.12 0 1.88-.24 2.36-.48 1.04-.54 1.28-1.32 1.42-2.02l1.44-6.74c.44-2.06-1.12-3.56-7.52-3.56-5.58 0-9.12 1.62-9.12 4.16 0 .92.84 1.6 3.22 1.6 2.2 0 3.64-.4 3.84-1.34l.3-1.42c.06-.32.52-.56 1.24-.56.76 0 1.08.24 1.02.56l-.5 2.32-.78.14c-3.88.7-9.94.86-9.94 4.6 0 1.7 1.6 2.74 4.46 2.74 2.64 0 4.88-.84 6.44-3.04h.4l-.54.74c-.9 1.24-.16 2.3 2.26 2.3zm-4.56-2.84c-.9 0-1.24-.32-1.24-.88 0-1.38.8-2 2.28-2.42l1-.28-.5 2.32c-.2.9-.74 1.26-1.54 1.26zm18.94-3.14c.84-1.9 2.08-4.98 2.26-5.86.38-1.8.38-1.8.38-1.94 0-1.06-1.58-1.56-3.78-1.56-2.36 0-4.26.58-4.52 1.8-.02.1-.62 3.16-.62 6.62 0 .34.02.6.02.94.019 1.153 1.37 1.253 2.7 1.26h.16c1.38 0 2.86-.06 3.4-1.26zm-4.4 5.98c.78 0 1.68-.08 2.36-.3 1.14-.36 1.82-1.1 1.82-1.96 0-.62-.42-1.04-1.14-1.3-.58-.22-1.4-.3-2.22-.3-.78 0-1.68.08-2.34.3-1.16.36-1.82 1.08-1.82 1.96 0 .62.42 1.04 1.12 1.3.58.22 1.44.3 2.22.3z" />
            </svg>
          </RoundButton>
        </Form>
      )}
    </Formik>
  )
}

export default RoomNameForm
