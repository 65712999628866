import * as React from 'react'
import { Router } from '@reach/router'

import { Home } from '@components/Home'
import { RoomWrapper } from '@components/Room'
import { Admin } from '@components/Admin'
import { NotFound } from '@components/NotFound'

const IndexPage = () => {
  return (
    <React.StrictMode>
      <Router>
        <Home path="/" />
        <Admin path="/admin" />
        <RoomWrapper path=":roomId" />
        <NotFound default />
      </Router>
    </React.StrictMode>
  )
}

export default IndexPage
