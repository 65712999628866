/** @jsx jsx */
import * as React from 'react'
import { Box, Grid, Heading, Text, jsx } from 'theme-ui'
import { RouteComponentProps } from '@reach/router'
import { observer } from 'mobx-react-lite'

import { Layout } from '@components/Layout'
import { SEO } from '@components/SEO'
import { Button } from '@components/Button'
import { Video } from '@components/Video'
import { PeerVideo } from '@components/PeerVideo'
import Chat from '@components/Chat'
import { PopoverSettings } from '@components/PopoverSettings'
import Requests from '@components/Requests'
import CopyRoomLink from '@components/CopyRoomLink'
import JoinRoomForm from '@components/forms/JoinRoomForm'
import ErrorBoundary from '@components/ErrorBoundary'
import { CallControls } from '@components/CallControls'
import { RoomFrame } from '@components/RoomFrame'

import { RoomState } from '@stores/models/Room'
import { useStore } from '@stores/useStore'
import { RoomProvider } from '@stores/RoomProvider'

const Room = observer(function Room({
  location,
  navigate
}: RouteComponentProps<{
  location: { state?: { action?: string } }
}>) {
  const room = useStore()
  const action = location?.state?.action
  const joinAction = room.exists && !room.settings.locked && action === 'join'
  const createAction = room.exists === false && action === 'create'
  const waitForAction = joinAction || createAction

  React.useEffect(() => {
    const joinRoom = async () => {
      try {
        await room.join()
        navigate(location.pathname, { replace: true })
      } catch (e) {
        console.error(e)
      }
    }
    if (waitForAction) {
      joinRoom()
    }
  }, [waitForAction])

  if (room.state === RoomState.WAITING_TO_JOIN) {
    return (
      <RoomFrame id={room.id}>
        <Heading>{room.id}</Heading>
        <Text>Loading room {room.id}…</Text>
      </RoomFrame>
    )
  }

  if (room.state === RoomState.DECLINED) {
    return (
      <RoomFrame id={room.id}>
        <Heading>Access declined</Heading>
        <Text>You were declined access to “{room.id}”.</Text>
      </RoomFrame>
    )
  }

  if (room.state === RoomState.WAITING_FOR_ACCESS) {
    return (
      <RoomFrame id={room.id}>
        <Heading>{room.id}</Heading>
        <Text>Waiting for someone to let you in…</Text>
        <Button disabled>Request to join</Button>
      </RoomFrame>
    )
  }

  if (room.state === RoomState.ON_HOLD) {
    return (
      <RoomFrame id={room.id}>
        <Heading>{room.id}</Heading>
        <Text>
          Your request has been seen, but put on hold. Wait for someone to let
          you in.
        </Text>
        <Button disabled>Request to join</Button>
      </RoomFrame>
    )
  }

  if (room.state === RoomState.REQUIRES_ACTION) {
    if (room.full) {
      return (
        <RoomFrame id={room.id}>
          <Heading>{room.id}</Heading>
          <Text>Unfortunately this room has reached its maximum capacity.</Text>
        </RoomFrame>
      )
    }

    if (waitForAction) {
      return (
        <RoomFrame id={room.id}>
          <Heading>{room.id}</Heading>
          <Text>Loading room {room.id}…</Text>
        </RoomFrame>
      )
    }

    if (room.exists && room.settings.locked) {
      return (
        <RoomFrame id={room.id}>
          <Heading>{room.id} is locked</Heading>

          <Box>
            <Text>
              This is a locked room. Enter your name and request permission to
              enter.
            </Text>
            <JoinRoomForm />
          </Box>
        </RoomFrame>
      )
    }

    return (
      <RoomFrame id={room.id}>
        <Heading>{`${room.exists ? 'Join' : 'Create'} room ${
          room.id
        }`}</Heading>

        <Text>
          {room.exists
            ? `You are about to join the room “${room.id}”`
            : `You are about to create the room “${room.id}”`}
        </Text>
        <JoinRoomForm />
      </RoomFrame>
    )
  }

  return (
    <Layout
      onClick={(ev) => {
        if (
          ev.target.id === 'main' ||
          ev.target.classList.contains('peerVideo')
        ) {
          if (room.panelOpen) {
            room.setPanelOpen(false)
          } else {
            room.toggleMinimalMode()
          }
        }
      }}
    >
      <SEO title={room.id} />

      <Requests />

      {room.isEmpty && (
        <Grid
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bg: 'grays.black',
            minWidth: '22em',
            maxWidth: '100%',
            justifyContent: 'center',
            gridRowGap: 3,
            textAlign: 'center',
            p: 4,
            borderRadius: 4,
            border: (theme) => `1px solid ${theme.colors.border}`
          }}
        >
          <Text>
            The room is empty! Share this URL to invite some people to chat:
          </Text>
          <CopyRoomLink />
        </Grid>
      )}

      {room.connectedPeers.map((p, i) => (
        <PeerVideo key={p.id} peer={p} position={room.layout.positions[i]} />
      ))}

      <Video />
      <Box
        sx={{
          position: 'fixed',
          bottom: [
            (theme) => `calc(var(--handle-size) + ${theme.space[3]}px)`,
            3
          ],
          left: '50%',
          transform: `translate3d(-50%, ${room.minimalMode ? '250%' : '0'}, 0)`,
          transition: 'transform 0.25s cubic-bezier(0,0,0,1.4)'
        }}
      >
        <CallControls />
      </Box>

      <Chat />
      <PopoverSettings />
    </Layout>
  )
})

export const RoomWrapper = ({
  roomId,
  ...routeProps
}: RouteComponentProps<{ roomId: string }>) => {
  if (!roomId)
    return <Text>What! This should not happen. We cant find the room id.</Text>
  return (
    <ErrorBoundary>
      <RoomProvider id={roomId}>
        <Room {...routeProps} />
      </RoomProvider>
    </ErrorBoundary>
  )
}
