/** @jsx jsx */
import * as React from 'react'
import { jsx, Flex, AspectRatio } from 'theme-ui'
import { animated, useSpring } from 'react-spring'
import { useDrag } from 'react-use-gesture'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'
import { observer } from 'mobx-react-lite'
import { autorun } from 'mobx'
import { useBreakpointIndex } from '@theme-ui/match-media'

import useWindowSize from '@hooks/useWindowSize'
import { useStore } from '@stores/useStore'
import VolumeIndicator from '@components/VolumeIndicator'
import { StreamStateIcons } from '@components/StreamStateIcons'

export const Video = observer(function Video() {
  const {
    handleSize,
    user,
    user: { stream }
  } = useStore()
  const isSmall = useBreakpointIndex() === 0
  const margin = { bottom: 20, top: 20, left: 20, right: 20 }
  const videoRef = React.useRef<HTMLVideoElement>(null)
  const windowSize = useWindowSize()
  const [ref, { height, width }] = useMeasure({
    polyfill: ResizeObserver
  })

  const initialX = margin.left
  const initialY =
    (windowSize.height || window.innerWidth) -
    margin.bottom -
    (isSmall ? handleSize : 0) -
    height
  const [{ x, y }, set] = useSpring(
    () => ({
      x: 0,
      y: 0,
      config: { tension: 280, friction: 28 }
    }),
    []
  )

  const bounds = React.useMemo(
    () => ({
      left: -initialX + margin.left,
      right:
        (windowSize.width || window.innerWidth) -
        initialX -
        width -
        margin.right,
      top: -initialY + margin.top,
      bottom:
        (windowSize.height || window.innerHeight) -
        initialY -
        height -
        margin.bottom -
        (isSmall ? handleSize : 0)
    }),
    [windowSize.width, windowSize.height, width, height, handleSize]
  )

  const bind = useDrag(
    ({ offset: [xo, yo] }) => {
      set({ x: xo, y: yo })
    },
    {
      bounds,
      rubberband: true
    }
  )

  React.useEffect(() => {
    if (x.get() < bounds.left) set({ x: bounds.left })
    if (x.get() > bounds.right) set({ x: bounds.right })
    if (y.get() < bounds.top) set({ y: bounds.top })
    if (y.get() > bounds.bottom) set({ y: bounds.bottom })
  }, [bounds])

  const startCamera = async () => {
    try {
      const s = await stream.getStream()
      if (s && videoRef.current) videoRef.current.srcObject = s
    } catch (e) {
      console.log(e)
    }
  }

  React.useEffect(
    () =>
      autorun(() => {
        if (stream.stream) {
          console.log('starting camera')
          startCamera()
        }
      }),
    []
  )

  if (!stream) return null

  return (
    <animated.div
      sx={{
        bg: 'grays.500',
        position: 'absolute',
        top: initialY,
        left: initialX,
        width: ['40vw', 300],
        height: [
          `calc(40vw * (1 / ${stream.aspectRatio}))`,
          300 * (1 / stream.aspectRatio)
        ],
        touchAction: 'none',
        cursor: 'grab',
        userSelect: 'none',
        '&:active': {
          cursor: 'grabbing'
        },
        borderRadius: 2,
        overflow: 'hidden',
        boxShadow: '0 3px 14px rgba(0,0,0,0.5)'
      }}
      style={{ x, y }}
      ref={ref}
      {...bind()}
    >
      {stream.stream ? (
        <video
          sx={{
            display: 'block',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            transform: `scaleX(${user.mirroredStream ? -1 : 1})`,
            opacity: stream.videoEnabled ? 1 : 0
          }}
          playsInline
          autoPlay
          muted
          ref={videoRef}
        />
      ) : (
        <AspectRatio ratio={stream.aspectRatio} sx={{ width: '100%' }} />
      )}

      {stream.stream && (
        <VolumeIndicator
          stream={stream.stream}
          enabled={stream.audioEnabled}
          sx={{
            position: 'absolute',
            bottom: 2,
            right: 2
          }}
        />
      )}

      <Flex sx={{ position: 'absolute', top: 2, left: 2 }}>
        <StreamStateIcons stream={stream} />
      </Flex>
    </animated.div>
  )
})
