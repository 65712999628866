import SimplePeer, { SignalData } from 'simple-peer'
import {
  types,
  getRoot,
  getParent,
  getEnv,
  Instance,
  cast,
  applySnapshot,
  SnapshotOut
} from 'mobx-state-tree'

import { RoomEnv } from '@stores'
import { MessageType } from '@stores/models/Message'
import { RoomInstance } from '@stores/models/Room'
import { PeerStream, PeerStreamInstance } from '@stores/models/PeerStream'

export const Peer = types
  .model('Peer', {
    id: types.identifier,
    name: '',
    color: types.optional(types.string, 'blue'),
    connected: false,
    initiator: true,
    stream: types.optional(PeerStream, {}),
    muted: false,
    disconnecting: false
  })
  .views((self) => ({
    get hasStream() {
      return !!self.stream.stream
    },
    getSize(position: {
      width: number
      height: number
      top: number
      left: number
    }) {
      return position.width / position.height <= self.stream.aspectRatio
        ? {
            width: position.width,
            height: position.width * (1 / self.stream.aspectRatio)
          }
        : {
            height: position.height,
            width: position.height * self.stream.aspectRatio
          }
    }
  }))
  .actions((self) => {
    return {
      destroyStream() {
        self.stream.destroyStream()
      },
      handleSignal: (data: SignalData) => {
        const env = getEnv<RoomEnv>(self)
        env.socket.send('signal', {
          signal: data,
          id: self.id
        })
      },
      update: (data: {
        stream: SnapshotOut<PeerStreamInstance>
        color?: string
        name?: string
      }) => {
        const { stream, name = self.name, color = self.color } = data
        console.log('update', data)
        self.name = name
        self.color = color
        applySnapshot(self.stream, stream)
      },
      handleData(data: any) {
        try {
          const json = JSON.parse(data.toString())
          switch (json.type) {
            case 'message': {
              console.log('got message!', json.data)
              const room = getParent<RoomInstance>(self, 2)
              room.addMessage(json.data)
              break
            }
            case 'connect': {
              this.update(json.data)
              const room = getRoot<RoomInstance>(self)
              if (!self.initiator && room) {
                room.addMessage({
                  author: self.id,
                  type: MessageType.USER_JOINED,
                  timestamp: new Date()
                })
              }
              break
            }
            case 'peerUpdate':
              console.log('got update!', json.data)
              this.update(json.data)
              break
            default:
              console.warn('unhandled message', json)
              break
          }
        } catch (e) {
          console.warn('Could not parse data', data)
        }
      },
      handleStream: (stream: MediaStream) => {
        console.log('got STREAM')
        self.stream.stream = stream
      },
      toggleMuted() {
        self.muted = !self.muted
      }
    }
  })
  .actions((self) => {
    let peer = new SimplePeer({
      initiator: self.initiator,
      trickle: true
    })

    return {
      removeStream(stream: MediaStream) {
        peer.removeStream(stream)
      },
      handleClose() {
        console.log('CLOSE')
        self.connected = false
        self.destroyStream()
        if (peer) peer.destroy()
        peer = undefined
        const room = getRoot<RoomInstance>(self)
        room.removePeer(cast(self))
      },
      handleError(error: Error) {
        console.error('ERROR', error)
        this.handleClose()
      },
      replaceTrack(
        oldTrack: MediaStreamTrack,
        track: MediaStreamTrack,
        stream: MediaStream
      ) {
        peer.replaceTrack(oldTrack, track, stream)
      },
      signal: (data: SignalData) => {
        if (!peer) {
          console.warn('got signal, but peer was destroyed')
          self.recreate()
        }
        peer.signal(data)
      },
      addStream(stream: MediaStream) {
        console.log('adding stream')
        console.log(self.hasStream && self.stream.stream?.getTracks())
        peer.addStream(stream)
      },
      sendMessage(message: string) {
        peer.send(message)
      },
      handleConnect() {
        console.log('CONNECTED!')
        self.connected = true
        const env = getEnv<RoomEnv>(self)
        const json = JSON.stringify({
          type: 'connect',
          data: env.user.data
        })
        this.sendMessage(json)
      },
      recreate() {
        self.disconnecting = false
        if (peer) {
          console.log('peer exists already?', self.hasStream)
          return
        }
        console.log('recreating peer')
        peer = new SimplePeer({
          initiator: self.initiator,
          trickle: true
        })
        peer.on('signal', self.handleSignal)
        peer.on('connect', this.handleConnect)
        peer.on('data', self.handleData)
        peer.on('error', this.handleError)
        peer.on('close', this.handleClose)
        peer.on('stream', self.handleStream)
      },
      afterCreate() {
        peer.on('signal', self.handleSignal)
        peer.on('connect', this.handleConnect)
        peer.on('data', self.handleData)
        peer.on('error', this.handleError)
        peer.on('close', this.handleClose)
        peer.on('stream', self.handleStream)
      },
      beforeDestroy() {
        this.handleClose()
      }
    }
  })

export interface PeerInstance extends Instance<typeof Peer> {}

export function LatePeer() {
  return Peer
}
