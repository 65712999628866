/** @jsx jsx */
import * as React from 'react'
import { Text, Grid, jsx } from 'theme-ui'
import Portal from '@reach/portal'

import { Button } from '@components/Button'
import { BasicInput } from '@components/forms/Input'

function copyText(text: string): Promise<void> {
  if ('clipboard' in navigator) {
    return navigator.clipboard.writeText(text)
  }

  const body = document.body
  if (!body) {
    return Promise.reject(new Error('No document body.'))
  }

  const node = document.createElement('pre')
  node.style.width = '1px'
  node.style.height = '1px'
  node.style.position = 'fixed'
  node.style.top = '5px'
  node.textContent = text
  body.appendChild(node)
  const selection = getSelection()
  if (!selection) {
    return Promise.reject(new Error())
  }
  selection.removeAllRanges()
  const range = document.createRange()
  range.selectNodeContents(node)
  selection.addRange(range)
  document.execCommand('copy')
  selection.removeAllRanges()
  body.removeChild(node)
  return Promise.resolve()
}

const CopyRoomLink = () => {
  const copyRef = React.useRef<HTMLInputElement>(null)
  const [copied, setCopied] = React.useState(false)

  React.useEffect(() => {
    let timer
    if (copied) {
      timer = setTimeout(() => {
        setCopied(false)
      }, 3000)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [copied])

  return (
    <React.Fragment>
      <Grid
        sx={{
          gridTemplateColumns: '1fr',
          gridRowGap: 2,
          justifyContent: 'center'
        }}
      >
        <BasicInput
          ref={copyRef}
          readOnly
          value={window.location.href}
          onClick={(ev) => (ev.target as HTMLInputElement).select()}
          sx={{ textAlign: 'center' }}
          aria-label="Room URL"
        />
        <Button
          onClick={async () => {
            if (copyRef.current) {
              await copyText(copyRef.current.value)
              setCopied(true)
            }
          }}
          sx={{ justifySelf: 'center' }}
        >
          Copy room url
        </Button>
      </Grid>
      {copied && (
        <Portal>
          <Text
            sx={{
              position: 'absolute',
              top: 20,
              right: 20,
              width: 200,
              border: 'solid 1px hsla(0, 0%, 0%, 0.25)',
              boxShadow: '0px 2px 10px hsla(0, 0%, 0%, 0.25)',
              p: 2,
              bg: '#f0f0f0',
              textAlign: 'center',
              color: 'black'
            }}
          >
            URL copied to clipboard!
          </Text>
        </Portal>
      )}
    </React.Fragment>
  )
}

export default CopyRoomLink
