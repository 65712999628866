import adapter from 'webrtc-adapter'

type AudioAnalyzer = {
  analyzer: AnalyserNode
  subscribeFrequencyData: (onData: (data: Uint8Array) => void) => void
  destroy: () => void
}

/**
 * Create AudioAnalyzer object to be used in audio-processing graph
 * MDN doc: https://developer.mozilla.org/en-US/docs/Web/API/AnalyserNode
 *
 * @export
 * @param {AudioContext} ctx
 * @returns {AudioAnalyzer}
 */
export function createAnalyzer(ctx: AudioContext, fftSize = 32): AudioAnalyzer {
  let rafId: number | undefined // scheduled requestAnimationFrame id
  let unsubscribed = false
  const analyzer = ctx.createAnalyser()
  analyzer.fftSize = fftSize // size of FFT (Fast Fourier Transform)
  analyzer.smoothingTimeConstant = 0.65 // the averaging constant with the last analysis frame

  return {
    analyzer,
    subscribeFrequencyData: (onData: (data: Uint8Array) => void) => {
      unsubscribed = false
      // Allocate Uint8Array size as FFT size
      const dataArray = new Uint8Array(analyzer.frequencyBinCount)
      const watch = () => {
        if (!unsubscribed) {
          // Get current frequency data in byte
          analyzer.getByteFrequencyData(dataArray)

          // Pass data to onData callback
          onData(dataArray)

          // Schedule next watch
          rafId = window.requestAnimationFrame(watch)
        }
      }

      rafId = window.requestAnimationFrame(watch)
    },
    destroy: () => {
      unsubscribed = true
      if (rafId !== undefined) {
        window.cancelAnimationFrame(rafId)
        rafId = undefined
      }
      analyzer.disconnect() // Disconnect AnalyserNode from audio-processing graph
    }
  }
}

/**
 * Create necessary AudioNodes with AudioContext
 * in order to subscribe frequency data changes from MediaStream
 *
 * Audio processing graph used in StreamAudioAnalyzer:
 *
 * ( created from MediaStream )
 *  MediaStreamAudioSourceNode --> AnalyserNode
 *
 * @export
 * @class StreamAudioAnalyzer
 *
 * @property {MediaStream} _stream - Stream obtained using the WebRTC or Media Capture and Streams APIs
 * @property {AudioContext} _audioCtx - Context to build audio-processing graph with
 * @property {MediaStreamAudioSourceNode} _source - AudioNode made from MediaStream to be used in audio-processing graph
 * @property {AudioAnalyzer} _audioAnalyzer - createAnalyzer() returned object, property analyzer as AnalyserNode
 */
export class StreamAudioAnalyzer {
  private _audioCtx?: AudioContext
  private _stream?: MediaStream
  private _clonedStream: boolean
  private _source?: MediaStreamAudioSourceNode
  private _audioAnalyzer?: AudioAnalyzer
  /**
   * Creates an instance of StreamAudioAnalyzer.
   *
   * @constructor
   * @param {MediaStream} stream - Stream obtained using the WebRTC or Media Capture and Streams APIs
   * @param {AudioContext} audioContext - Context to build audio-processing graph with
   * @memberof StreamAudioAnalyzer
   */
  constructor(stream: MediaStream, fftSize = 32) {
    this._audioCtx = new (window.AudioContext || window.webkitAudioContext)()
    this._clonedStream = false
    this._stream = stream
    if (adapter.browserDetails.browser === 'safari') {
      // Clone stream to work around Safari streamSource bug:
      // https://github.com/ai/audio-recorder-polyfill/issues/17
      // https://stackoverflow.com/questions/58564669/audionode-disconnect-followed-by-connect-not-working-in-safari
      this._clonedStream = true
      this._stream = stream.clone()
      // Un-mute any any audio tracks in case they were muted when we cloned them:
      this._stream.getAudioTracks().forEach((t) => (t.enabled = true))
    }
    this._source = this._audioCtx.createMediaStreamSource(this._stream)
    this._audioAnalyzer = createAnalyzer(this._audioCtx, fftSize)
    this._source.connect(this._audioAnalyzer.analyzer)
  }

  /**
   * Clear current using AudioNodes in audio-processing graph
   * and close current AudioContext
   *
   *
   * @memberof StreamAudioAnalyzer
   */
  _clearAudioResources() {
    this._audioAnalyzer?.destroy()
    this._audioAnalyzer = undefined
    this._source?.disconnect()
    this._source = undefined
    // We cloned the original stream so we must clean up and stop all tracks:
    if (this._clonedStream) this._stream?.getTracks().forEach((t) => t.stop())
    this._stream = undefined
    this._audioCtx?.close()
    this._audioCtx = undefined
  }

  /**
   * Subscribe to current MediaStream frequency data changes
   *
   * @param {Function} onData - callback when frequency data changes
   * @returns {clearAudioResourcesCallback} - clear related Audio Nodes callback
   * @memberof StreamAudioAnalyzer
   */
  subscribeData(onData: (data: Uint8Array) => void) {
    this._audioAnalyzer?.subscribeFrequencyData(onData)
    return () => this._clearAudioResources()
  }
}
