/** @jsx jsx */
import { jsx, Grid } from 'theme-ui'
import { navigate } from 'gatsby'
import { observer } from 'mobx-react-lite'
import {
  FiMicOff,
  FiMic,
  FiCamera,
  FiCameraOff,
  FiMonitor,
  FiRepeat,
  FiX
} from 'react-icons/fi'

import { useStore } from '@stores/useStore'
import { RoundButton } from '@components/Button'
import { Tooltip } from '@components/Tooltip'

export const CallControls = observer(() => {
  const {
    declinedPermissions,
    user: { stream }
  } = useStore()

  const handleScreenClick = async () => {
    try {
      if (!stream.sharingScreen && declinedPermissions) {
        await stream.getScreen()
      } else {
        await stream.replaceStream(!stream.sharingScreen)
      }
    } catch (e) {
      if (stream.sharingScreen) stream.destroyStream()
      console.log(e)
    }
  }

  const switchCamera = () => {
    try {
      stream.switchCamera()
    } catch (e) {
      console.log(e)
    }
  }

  if (!stream) return null

  return (
    <Grid
      sx={{
        gridColumnGap: 2,
        gridTemplateColumns: 'repeat(5, auto)'
      }}
    >
      <Tooltip
        label={stream.videoEnabled ? 'Turn camera off' : 'Turn camera on'}
      >
        <RoundButton
          variant="dark"
          onClick={stream.toggleVideo}
          disabled={stream.sharingScreen || declinedPermissions}
        >
          {stream.videoEnabled ? <FiCamera /> : <FiCameraOff />}
        </RoundButton>
      </Tooltip>
      <Tooltip label={stream.audioEnabled ? 'Turn mic off' : 'Turn mic on'}>
        <RoundButton
          variant="dark"
          onClick={stream.toggleAudio}
          disabled={declinedPermissions}
        >
          {stream.audioEnabled ? <FiMic /> : <FiMicOff />}
        </RoundButton>
      </Tooltip>
      {stream.hasMultipleVideoDevices && (
        <Tooltip label="Switch camera">
          <RoundButton
            variant="dark"
            onClick={switchCamera}
            disabled={stream.sharingScreen}
          >
            <FiRepeat />
          </RoundButton>
        </Tooltip>
      )}
      {stream.hasScreenSharing && (
        <Tooltip label={stream.sharingScreen ? 'Stop sharing' : 'Share screen'}>
          <RoundButton variant="dark" onClick={handleScreenClick}>
            <FiMonitor
              sx={{ stroke: stream.sharingScreen ? 'error' : 'white' }}
            />
          </RoundButton>
        </Tooltip>
      )}
      <Tooltip label="End call">
        <RoundButton
          variant="dark"
          onClick={() => navigate('/')}
          sx={{ bg: 'error' }}
        >
          <FiX />
        </RoundButton>
      </Tooltip>
    </Grid>
  )
})
