import { types, getEnv } from 'mobx-state-tree'

import { RoomEnv } from '@stores'

export const RoomSettings = types
  .model('RoomSettings', {
    locked: false,
    requiresMedia: true,
    maxPeers: 10
  })
  .actions((self) => ({
    setMaxPeers: (max: number) => {
      self.maxPeers = Math.min(10, Math.max(2, max))
      getEnv<RoomEnv>(self).socket.send('setMaxPeers', {
        max: self.maxPeers
      })
    },
    setLocked(locked: boolean) {
      self.locked = locked
      getEnv<RoomEnv>(self).socket.send('setLocked', { locked })
    },
    setRequiresMedia(required: boolean) {
      self.requiresMedia = required
      getEnv<RoomEnv>(self).socket.send('setRequiresMedia', { required })
    }
  }))
