/** @jsx jsx */
import * as React from 'react'
import { jsx, Box, ButtonProps } from 'theme-ui'
import { CustomTheme } from '@theme'

const baseButton = {
  appearance: 'none',
  fontFamily: 'inherit',
  lineHeight: 'inherit',
  display: 'inline-block',
  borderStyle: 'solid',
  px: 3,
  py: 2,
  fontSize: 'inherit',
  textDecoration: 'none',
  textAlign: 'center',
  cursor: 'pointer',
  transition: 'background-color 0.3s ease-out',
  '&:focus': {
    outline: 'none',
    borderColor: 'transparent',
    boxShadow: (theme: CustomTheme) => `0 0 0 2px ${theme.colors.pink}`
  }
}

export const Button = React.forwardRef(function Button(
  props: ButtonProps,
  ref: React.Ref<HTMLButtonElement>
) {
  return (
    <Box
      as="button"
      __themeKey="buttons"
      sx={{
        ...baseButton,
        minWidth: '6.25em'
      }}
      type="button"
      variant="primary"
      {...props}
      ref={ref}
    />
  )
})

export const RoundButton = React.forwardRef(function RoundButton(
  {
    size = '3em',
    ...rest
  }: ButtonProps & { size?: number | string | Array<string | number> },
  ref: React.Ref<HTMLButtonElement>
) {
  return (
    <Box
      as="button"
      __themeKey="buttons"
      sx={{
        ...baseButton,
        width: size,
        height: size,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        p: 2,
        svg: {
          width: '80%',
          height: '80%'
        }
      }}
      type="button"
      variant="round"
      {...rest}
      ref={ref}
    />
  )
})
