import * as React from 'react'
import { Input as StyledInput, InputProps } from 'theme-ui'
import { FieldProps } from 'formik'

export const Input = React.forwardRef(function Input(
  { field, form, type = 'text', ...props }: InputProps & FieldProps,
  ref: React.Ref<HTMLInputElement>
) {
  return (
    <StyledInput
      sx={{ bg: 'grays.500', py: 2, px: 3, borderColor: 'border' }}
      type={type}
      {...field}
      {...props}
      ref={ref}
    />
  )
})

export const BasicInput = React.forwardRef(function BasicInput(
  props: InputProps,
  ref: React.Ref<HTMLInputElement>
) {
  return (
    <StyledInput
      sx={{ bg: 'grays.500', py: 2, px: 3, borderColor: 'border' }}
      {...props}
      ref={ref}
    />
  )
})
