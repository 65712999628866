/** @jsx jsx */
import * as React from 'react'
import { jsx } from 'theme-ui'
import { observer } from 'mobx-react-lite'

import { StreamAudioAnalyzer } from '@helpers/audioAnalyzer'

interface VolumeIndicatorProps {
  stream: MediaStream | undefined
  enabled: boolean
}

const VolumeIndicator = observer<VolumeIndicatorProps>(
  ({ stream, enabled, ...rest }) => {
    const ref = React.useRef<HTMLDivElement>(null)

    React.useEffect(() => {
      if (!stream || stream.getAudioTracks().length === 0) return
      console.log('running audio analyzer')
      try {
        const analyzer = new StreamAudioAnalyzer(stream, 32)
        if (analyzer) {
          // https://developer.mozilla.org/en-US/docs/Web/API/AnalyserNode/frequencyBinCount
          const frequencyBinCount = 32 / 2
          const unsubscribe = analyzer.subscribeData((frequencyLevels) => {
            const averageLevel =
              frequencyLevels.reduce((sum, level) => sum + level, 0) /
              frequencyBinCount
            requestAnimationFrame(() => {
              if (ref.current) {
                ref.current.style.transform = `scaleY(${averageLevel / 255})`
              }
            })
          })
          return unsubscribe
        }
      } catch (e) {
        console.error(
          'AudioIndicator: failed to instantiate StreamAudioAnalyzer',
          e
        )
      }
      return
    }, [stream])

    return (
      <div
        sx={{
          height: 80,
          width: 3,
          bg: 'black',
          pointerEvents: 'none',
          opacity: enabled ? 1 : 0
        }}
        {...rest}
      >
        <div
          ref={ref}
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',
            bg: 'pink',
            transformOrigin: 'center bottom'
          }}
        />
      </div>
    )
  }
)

export default VolumeIndicator
