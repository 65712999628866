/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui'
import * as React from 'react'
import { Link } from 'gatsby'
import VisuallyHidden from '@reach/visually-hidden'

import Logo from '@components/Logo'

export const Layout = ({
  children,
  ...rest
}: {
  children: React.ReactNode
  onClick?: (ev: React.MouseEvent<HTMLElement>) => void
}) => {
  const [, setColorMode] = useColorMode()

  React.useEffect(() => {
    setColorMode('dark')
    document.body.style.overflow = 'hidden'
    return () => {
      setColorMode('default')
      document.body.style.overflow = 'auto'
    }
  }, [])

  return (
    <main id="main" {...rest} sx={{ height: '100%' }}>
      <Link
        to="/"
        sx={{
          position: 'fixed',
          top: 2,
          left: 2,
          zIndex: 999,
          '&:hover': { svg: { fill: 'white' } }
        }}
      >
        <Logo
          sx={{
            width: '3em',
            fill: 'pink',
            transition: 'fill 0.3s ease'
          }}
        />
        <VisuallyHidden>Home</VisuallyHidden>
      </Link>
      {children}
    </main>
  )
}
