/** @jsx jsx */
import * as React from 'react'
import { jsx } from 'theme-ui'
import { observer } from 'mobx-react-lite'
import { useBreakpointIndex } from '@theme-ui/match-media'

import { Button } from '@components/Button'
import { useStore } from '@stores/useStore'
import { PopOver } from '@components/PopOver'
import { FiSettings } from 'react-icons/fi'
import { Settings } from '@components/Settings'

export const PopoverSettings = observer(() => {
  const settingsButtonRef = React.useRef<HTMLButtonElement | null>(null)
  const [show, setShow] = React.useState(false)
  const isSmall = useBreakpointIndex() === 0
  const room = useStore()

  return (
    <React.Fragment>
      <Button
        ref={settingsButtonRef}
        onClick={isSmall ? undefined : () => setShow(true)}
        variant="dark"
        sx={{
          position: 'fixed',
          top: 3,
          left: '50%',
          transform: 'translateX(-50%)',
          display: 'flex',
          alignItems: 'center',
          gridTemplateColumns: '1fr max-content',
          whiteSpace: 'nowrap'
        }}
      >
        {isSmall ? (
          room.id
        ) : (
          <React.Fragment>
            {room.id} <FiSettings sx={{ ml: 2 }} />
          </React.Fragment>
        )}
      </Button>

      {!isSmall && (
        <PopOver
          targetRef={settingsButtonRef}
          show={show}
          handleDismiss={() => setShow(false)}
          title="Settings"
        >
          <Settings />
        </PopOver>
      )}
    </React.Fragment>
  )
})
