/** @jsx jsx */
import * as React from 'react'
import { Grid, jsx } from 'theme-ui'
import { observer } from 'mobx-react-lite'

import MessageGroup from '@components/MessageGroup'
import { MessageInstance } from '@stores/models/Message'

const MessagesListView = (
  {
    messages,
    handleScroll
  }: {
    messages: MessageInstance[]
    handleScroll?: (ev: any) => void
  },
  ref
) => {
  return (
    <Grid
      sx={{
        px: 2,
        pt: (theme) => `calc(var(--handle-size) + ${theme.space[3]}px)`,
        overflow: 'auto',
        webkitOverflowScrolling: 'touch',
        gridTemplateRows: 'auto',
        columnGap: 0,
        rowGap: 0
      }}
      onScroll={handleScroll ? handleScroll : undefined}
      ref={ref}
    >
      <Grid sx={{ alignContent: 'flex-end' }}>
        {messages.map((group, i) => (
          <MessageGroup key={`group-${i}`} messages={group} />
        ))}
      </Grid>
    </Grid>
  )
}

export default observer(MessagesListView, { forwardRef: true })
