/** @jsx jsx */
import * as React from 'react'
import { jsx, Text } from 'theme-ui'
import { observer } from 'mobx-react-lite'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'

import { Button } from '@components/Button'
import { Input } from '@components/forms/Input'
import { useStore } from '@stores/useStore'

const nameSchema = yup.object().shape({
  name: yup.string()
})

const UserNameForm = (props) => {
  const { user } = useStore()

  return (
    <Formik
      initialValues={{ name: user.name }}
      validationSchema={nameSchema}
      onSubmit={(values, { setSubmitting, setFieldTouched }) => {
        user.setName(values.name.trim())
        setFieldTouched('name', false)
        setSubmitting(false)
      }}
    >
      {({ values, isSubmitting, setFieldTouched }) => (
        <Form
          sx={{
            display: 'grid',
            alignItems: 'center',
            gridTemplateColumns: '1fr max-content',
            gridColumnGap: 2
          }}
          {...props}
        >
          <Field
            name="name"
            autoComplete="off"
            spellCheck="false"
            onClick={() => setFieldTouched('name', true)}
            onBlur={() => {
              setFieldTouched('name', false)
            }}
            component={Input}
          />
          <Button
            type="submit"
            disabled={values.name === user.name || isSubmitting}
          >
            Save
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default observer(UserNameForm)
