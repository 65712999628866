import { types, Instance } from 'mobx-state-tree'

export const PeerStream = types
  .model('PeerStream', {
    audioEnabled: true,
    videoEnabled: true,
    sharingScreen: false,
    aspectRatio: 4 / 3
  })
  .volatile((): {
    stream: MediaStream | undefined
  } => ({
    stream: undefined
  }))
  .actions((self) => ({
    destroyStream() {
      if (self.stream) {
        self.stream.getTracks().forEach((track) => {
          track.stop()
        })
        self.audioEnabled = false
        self.videoEnabled = false
        self.sharingScreen = false
        self.stream = undefined
      }
    },
    beforeDestroy() {
      this.destroyStream()
    }
  }))

export interface PeerStreamInstance extends Instance<typeof PeerStream> {}
