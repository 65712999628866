/** @jsx jsx */
import { Flex, jsx } from 'theme-ui'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import { observer } from 'mobx-react-lite'

import Message from '@components/Message'
import SystemMessage from '@components/SystemMessage'
import { MessageInstance } from '@stores/models/Message'

const MessageGroup = observer(
  ({ messages }: { messages: MessageInstance[] }) => {
    return messages[0].isSystemMessage ? (
      <Flex
        sx={{
          flexDirection: 'column',
          maxWidth: '100%',
          justifySelf: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          paddingBottom: 3
        }}
      >
        {messages.map((message, i) => (
          <SystemMessage key={`system-message-${i}`} message={message} />
        ))}
      </Flex>
    ) : (
      <Flex
        sx={{
          flexDirection: 'column',
          maxWidth: '85%',
          justifySelf: messages[0].isOwner ? 'flex-end' : 'flex-start',
          alignItems: messages[0].isOwner ? 'flex-end' : 'flex-start',
          justifyContent: 'flex-end',
          paddingBottom: 3
        }}
      >
        {messages.map((message, i) => (
          <Message
            key={`message-${i}`}
            message={message}
            last={messages.length - 1 === i}
            showTime={
              i === 0 ||
              differenceInMinutes(
                message.timestamp,
                messages[i - 1].timestamp
              ) >= 1
            }
            showUser={!message.isOwner && i === 0}
          />
        ))}
      </Flex>
    )
  }
)

export default MessageGroup
