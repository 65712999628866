/** @jsx jsx */
import { jsx, Heading, Alert, Grid, Text } from 'theme-ui'
import * as React from 'react'

import { Layout } from '@components/Layout'

class ErrorBoundary extends React.PureComponent<{}, { error: Error | null }> {
  state = {
    error: null
  }

  static getDerivedStateFromError(error: Error) {
    return { error }
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    console.error(info.componentStack)
  }

  render() {
    const { error } = this.state
    if (error) {
      return (
        <Layout>
          <Grid
            sx={{
              justifyContent: 'center',
              gridRowGap: 6,
              pb: 6,
              minHeight: '100vh',
              alignItems: 'center'
            }}
          >
            <Grid
              sx={{
                bg: 'grays.600',
                p: 3,
                borderBottom: (theme) => `3px solid ${theme.colors.grays[400]}`,
                maxWidth: '28em',
                mx: 2
              }}
            >
              <Heading>Something went wrong</Heading>
              <Text>
                We hit an unexpected error. Please reload the page to try again.
              </Text>
              <Alert variant="error">{error.message}</Alert>
            </Grid>
          </Grid>
        </Layout>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
