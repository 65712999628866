/** @jsx jsx */
import * as React from 'react'
import { destroy } from 'mobx-state-tree'
import { navigate } from 'gatsby'
import { jsx, Spinner, Grid, Text, Heading } from 'theme-ui'

import { createStore, RoomContext } from '@stores'
import { RoomInstance } from '@stores/models/Room'
import { Layout } from '@components/Layout'
import { FriendlyWebSocket } from '@utils/FriendlySocket'
import { MockWebSocket } from '@utils/MockSocket'
import { RoomFrame } from '@components/RoomFrame'
import { Button } from '@components/Button'
import { generateRandomName } from '@components/forms/RoomName'

const LOCALSTORAGE_CREDENTIALS = 'credentials'

export const MockProvider = ({
  id,
  children
}: {
  id: string
  children: React.ReactNode
}) => {
  const [store] = React.useState<null | RoomInstance>(
    createStore('uuid', false, id, new MockWebSocket())
  )

  return <RoomContext.Provider value={store}>{children}</RoomContext.Provider>
}

export const RoomProvider = ({
  children,
  id
}: {
  children: React.ReactNode
  id: string
}) => {
  const socket = React.useRef<FriendlyWebSocket | null>(null)
  const [store, setStore] = React.useState<null | RoomInstance>(null)
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    console.log('running effect', store)
    setLoading(true)
    // Room id changed?
    if (store) {
      destroy(store)
      setStore(null)
    }
    if (socket.current) socket.current.disconnect()

    socket.current = new FriendlyWebSocket(process.env.GATSBY_WEBSOCKET_URL, {
      autoReconnect: true
    })
    const handleMessage = (message: {
      type: string
      data: {
        id: string
        [key: string]: any
      }
    }) => {
      if (message.type === 'welcome') {
        console.log('WELCOME:', message.data)
        try {
          localStorage.setItem(
            LOCALSTORAGE_CREDENTIALS,
            JSON.stringify(message.data.credentials)
          )
        } catch (e) {
          console.log(e)
        }

        setStore(
          createStore(
            message.data.credentials.id,
            message.data.reconnected,
            id,
            socket.current
          )
        )
        setLoading(false)
      }
    }
    socket.current.on('open', () => {
      console.log('open socket')
      let cred = {}
      try {
        const credentials = localStorage.getItem(LOCALSTORAGE_CREDENTIALS)
        const json = JSON.parse(credentials)
        if (json) cred = { roomId: id, ...json }
        console.log('emitting existing credentials')
      } catch (e) {
        console.log(e)
      }
      socket.current.send('register', cred)
    })
    socket.current.on('message', handleMessage)
    socket.current.on('close', () => {
      console.log('socket closed')
      setLoading(false)
      if (store) destroy(store)
      setStore(null)
    })
    return () => {
      console.log('unmount')
      if (socket.current) {
        socket.current.send('leave')
        socket.current.disconnect()
      }
      if (store) destroy(store)
    }
  }, [id])

  if (loading) {
    return (
      <Layout>
        <Grid
          sx={{
            justifyContent: 'center',
            minHeight: '100%',
            alignItems: 'center'
          }}
        >
          <Spinner />
        </Grid>
      </Layout>
    )
  }

  if (!store)
    return (
      <RoomFrame>
        <Heading>Something went wrong here…</Heading>
        <Text>
          We could not connect to the server. Please reload the page or click
          below to try again.
        </Text>
        <Button
          variant="primary"
          onClick={() => (window.location = window.location.href)}
          sx={{
            alignSelf: 'flex-start',
            justifySelf: 'center',
            px: 4
          }}
        >
          Reload
        </Button>
        <Button
          variant="dark"
          onClick={() => navigate(`/${generateRandomName()}`)}
          sx={{
            alignSelf: 'flex-start',
            justifySelf: 'center',
            px: 4,
            bg: 'unset',
            color: 'grays.300'
          }}
        >
          Try another room→
        </Button>
      </RoomFrame>
    )

  return <RoomContext.Provider value={store}>{children}</RoomContext.Provider>
}
