import { createContext } from 'react'
import omit from 'lodash/omit'
import { onSnapshot, SnapshotOut } from 'mobx-state-tree'

import { FriendlyWebSocket } from '@utils/FriendlySocket'
import { User, UserInstance } from '@stores/models/User'
import { Room, RoomInstance } from '@stores/models/Room'

export type RoomEnv = {
  socket: FriendlyWebSocket
  user: UserInstance
}

const LOCALSTORAGE_ID = 'blablabla'

export const createStore = (
  id: string,
  reconnected: boolean,
  roomId: string,
  socket: FriendlyWebSocket
): RoomInstance => {
  let initialUserState: Partial<Omit<SnapshotOut<UserInstance>, 'id'>> = {}
  try {
    const storedUserState = localStorage.getItem(LOCALSTORAGE_ID)
    if (storedUserState) {
      const json = JSON.parse(storedUserState)
      if (User.is({ id, ...json })) initialUserState = json
    }
  } catch (e) {
    console.log('No initial state found')
  }

  const user = User.create({
    id,
    ...initialUserState,
    stream: {
      cameraConstraints: {
        video: {
          deviceId: initialUserState.lastVideoDevice || undefined
        },
        audio: {
          deviceId: initialUserState.lastAudioDevice || undefined
        }
      }
    }
  })

  const env: RoomEnv = { user, socket }

  onSnapshot(user, (snapshot) => {
    try {
      localStorage.setItem(
        LOCALSTORAGE_ID,
        JSON.stringify(omit(snapshot, ['id', 'stream']))
      )
    } catch (e) {
      console.warn('Could not persist user to local storage.')
    }
  })

  console.log('creating room store')
  return Room.create({ id: roomId, reconnected, user }, env)
}

export const RoomContext = createContext<null | RoomInstance>(null)
