/** @jsx jsx */
import * as React from 'react'
import { jsx, Text, Box } from 'theme-ui'
import Popover, { Position } from '@reach/popover'
import { useTransition, animated } from 'react-spring'
import { observer } from 'mobx-react-lite'

const positionCentered: Position = (triggerRect, tooltipRect) => {
  if (!triggerRect || !tooltipRect) {
    return {}
  }
  const triggerCenter = triggerRect.left + triggerRect.width / 2
  const left = triggerCenter - tooltipRect.width / 2
  const maxLeft = window.innerWidth - tooltipRect.width - 2
  return {
    left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
    top: triggerRect.bottom + window.scrollY
  }
}

export const PopOver = observer(
  ({
    targetRef,
    show,
    handleDismiss,
    children,
    title
  }: {
    children: React.ReactNode
    show: boolean
    targetRef: React.Ref<HTMLButtonElement>
    handleDismiss: () => void
    title?: string
  }) => {
    const transition = useTransition(show, {
      from: { opacity: 0, scale: 0.94 },
      enter: { opacity: 1, scale: 1 },
      leave: { opacity: 0, scale: 0.94 },
      config: { tension: 360, friction: 24 }
    })

    return (
      show &&
      transition(({ opacity, scale }, item) => {
        return (
          item && (
            <React.Fragment>
              <animated.div
                sx={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  bg: 'rgba(0,0,0,0.35)',
                  pointerEvents: show ? 'all' : 'none'
                }}
                onClick={handleDismiss}
                style={{ opacity }}
              />
              <Popover targetRef={targetRef} position={positionCentered}>
                <animated.div
                  sx={{
                    p: 3,
                    m: 2,
                    bg: 'grays.600',
                    maxWidth: 400,
                    borderRadius: 5,
                    border: (theme) => `1px solid ${theme.colors.border}`,
                    transformOrigin: 'center top'
                  }}
                  style={{ opacity, scale }}
                  onKeyDown={(ev) => {
                    if (ev.key === 'Escape') handleDismiss()
                  }}
                >
                  {title && (
                    <Text
                      sx={{
                        bg: 'grays.400',
                        borderRadius: 4,
                        fontWeight: 600,
                        p: 2,
                        textAlign: 'center'
                      }}
                    >
                      {title}
                    </Text>
                  )}
                  <Box sx={{ px: 1, py: 3 }}>{children}</Box>
                </animated.div>
              </Popover>
            </React.Fragment>
          )
        )
      })
    )
  }
)
