/** @jsx jsx */
import * as React from 'react'
import { Box, Heading, Text, jsx } from 'theme-ui'
import { observer } from 'mobx-react-lite'
import Portal from '@reach/portal'

import { useStore } from '@stores/useStore'
import { Button } from '@components/Button'

const Requests = observer(() => {
  const room = useStore()
  if (!room) return null

  return room.requestsToJoinArray.length > 0 ? (
    <Portal>
      {room.requestsToJoinArray.map((request) => (
        <Box
          key={`request-${request.id}`}
          sx={{
            position: 'absolute',
            top: 20,
            right: 20,
            width: 300,
            border: 'solid 1px hsla(0, 0%, 0%, 0.25)',
            boxShadow: '0px 2px 10px hsla(0, 0%, 0%, 0.25)',
            p: 2,
            background: '#f0f0f0',
            color: 'black'
          }}
        >
          <strong>{request.name}</strong> wants to join the room
          <Button onClick={request.grant}>Allow access</Button>
          <Button onClick={request.decline} variant="secondary">
            Decline access
          </Button>
          <Button onClick={request.hold} variant="secondary">
            Put on hold
          </Button>
        </Box>
      ))}
    </Portal>
  ) : null
})

export default Requests
