import { types, getEnv, Instance } from 'mobx-state-tree'

import { RoomEnv } from '@stores'
import { LatePeer } from '@stores/models/Peer'
import { LateUser } from '@stores/models/User'

const User = types.late(() => LateUser())
const Peer = types.late(() => LatePeer())

export enum MessageType {
  USER_LEFT = 'USER_LEFT',
  USER_JOINED = 'USER_JOINED',
  MESSAGE = 'MESSAGE'
}

export const Message = types
  .model('Message', {
    author: types.reference(types.union(User, Peer)),
    type: types.optional(
      types.enumeration<MessageType>('MessageType', Object.values(MessageType)),
      MessageType.MESSAGE
    ),
    content: types.maybe(types.string),
    timestamp: types.Date,
    read: false
  })
  .views((self) => ({
    get isSystemMessage() {
      return (
        self.type === MessageType.USER_JOINED ||
        self.type === MessageType.USER_LEFT
      )
    },
    get isOwner() {
      const room = getEnv<RoomEnv>(self)
      return room && self.author.id === room.user.id
    }
  }))
  .actions((self) => ({
    markAsRead() {
      self.read = true
    }
  }))
  .preProcessSnapshot((snapshot) => ({
    ...snapshot,
    timestamp:
      typeof snapshot.timestamp === 'string'
        ? new Date(snapshot.timestamp)
        : snapshot.timestamp
  }))

export interface MessageInstance extends Instance<typeof Message> {}
