import sortBy from 'lodash/sortBy'
import {
  types,
  getEnv,
  flow,
  SnapshotIn,
  Instance,
  IDisposer
} from 'mobx-state-tree'
import { values, autorun } from 'mobx'
import maxBy from 'lodash/maxBy'

import { RoomEnv } from '@stores'
import { Peer, PeerInstance } from '@stores/models/Peer'
import { User } from '@stores/models/User'
import { RoomSettings } from '@stores/models/RoomSettings'
import { RequestToJoin } from '@stores/models/RequestToJoin'
import { Message, MessageType, MessageInstance } from '@stores/models/Message'
import { Layout } from '@stores/models/Layout'

// const DEFAULT_RATIO = 4 / 3

/**
 * Possible room states:
 * - CONNECTED: user is connected and in the room
 * - WAITING_FOR_STREAM: waiting for a/v stream permissions (only when required by room)
 * - WAITING_FOR_ACCESS: waiting until access granted when a room is locked
 * - WAITING_TO_JOIN: user initiated join, waiting for the 'joined' event from server
 * - REQUIRES_ACTION: room needs to be created or joined
 * - ON_HOLD: request seen, put on hold
 * - DECLINED: room is full, access denied, or no stream when required
 */
export enum RoomState {
  CONNECTED = 'CONNECTED',
  WAITING_FOR_STREAM = 'WAITING_FOR_STREAM',
  WAITING_FOR_ACCESS = 'WAITING_FOR_ACCESS',
  WAITING_TO_JOIN = 'WAITING_TO_JOIN',
  REQUIRES_ACTION = 'REQUIRES_ACTION',
  ON_HOLD = 'ON_HOLD',
  DECLINED = 'DECLINED'
}

export enum ViewMode {
  FILL = 'FILL',
  FIT = 'FIT'
}

export const Room = types
  .model('Room', {
    id: types.identifier,
    peers: types.map(Peer),
    exists: types.maybe(types.boolean),
    full: false,
    messages: types.array(Message),
    settings: types.optional(RoomSettings, {}),
    requestsToJoin: types.array(RequestToJoin),
    state: types.optional(
      types.enumeration<RoomState>('RoomState', Object.values(RoomState)),
      RoomState.WAITING_TO_JOIN
    ),
    user: User,
    declinedPermissions: false,
    handleSize: 50,
    minimalMode: false,
    panelOpen: false,
    viewMode: types.optional(
      types.enumeration<ViewMode>('ViewMode', Object.values(ViewMode)),
      ViewMode.FILL
    ),
    reconnected: false,
    layout: types.optional(Layout, {})
  })
  .views((self) => ({
    get allPeers() {
      return values(self.peers)
    },
    get connectedPeers() {
      return values(self.peers).filter((peer) => peer.connected)
    },
    get isEmpty() {
      return this.connectedPeers.length === 0
    },
    get allStreams() {
      return this.connectedPeers
        .filter((peer) => peer.hasStream)
        .map((peer) => peer.stream)
    },
    get totalUsers() {
      return this.connectedPeers.length + 1
    },
    get requestsToJoinArray() {
      return values(self.requestsToJoin)
    },
    get sortedMessages() {
      return sortBy(self.messages, (m) => m.timestamp)
    },
    get groupedMessages() {
      const splitPoints = this.sortedMessages.reduce(
        (a, b, index) => {
          const lastMessage = this.sortedMessages[a[a.length - 1]]
          if (lastMessage) {
            if (lastMessage.isSystemMessage && b.isSystemMessage) return a // dont split between system messages
            if (lastMessage.author.id !== b.author.id) return [...a, index]
            if (
              lastMessage.author.id === b.author.id &&
              lastMessage.type !== b.type
            )
              return [...a, index]
            return a //[...a, index]
          }
          return a
        },
        [0]
      )

      const groupedMessages = []
      splitPoints.reduce((a, b) => {
        if (a !== undefined)
          groupedMessages.push(this.sortedMessages.slice(a, b))
        return b
      }, undefined)
      if (this.sortedMessages.length > 0) {
        groupedMessages.push(
          this.sortedMessages.slice(splitPoints[splitPoints.length - 1])
        )
      }
      return groupedMessages
    },
    get unreadMessageCount() {
      return this.sortedMessages.filter((m) => !m.isSystemMessage && !m.read)
        .length
    },
    get biggestRatio() {
      return maxBy(self.connectedPeers, 'stream.aspectRatio')
    }
  }))
  .actions((self) => {
    return {
      join: flow(function* join() {
        if (
          [
            RoomState.CONNECTED,
            RoomState.WAITING_TO_JOIN,
            RoomState.WAITING_FOR_ACCESS
          ].includes(self.state)
        ) {
          console.warn('already joining room')
          return
        }
        console.log('waiting for usermedia permissions')
        try {
          yield self.user.stream.getStream()
        } catch (e) {
          self.declinedPermissions = true
          self.user.stream.setVideoEnabled(false)
          self.user.stream.setAudioEnabled(false)
          console.warn(e)
          if (self.exists && self.settings.requiresMedia) {
            throw new Error(e)
          }
        }
        console.log('joining room', self.id, self.user.name)
        self.state = self.settings.locked
          ? RoomState.WAITING_FOR_ACCESS
          : RoomState.WAITING_TO_JOIN
        getEnv(self).socket.send('join', {
          id: self.id,
          name: self.user.name
        })
      }),
      handleMessage: flow(function* handleMessage(message: {
        type: string
        // TODO: Update to use SocketEvent types
        data: { [key: string]: any }
      }) {
        switch (message.type) {
          case 'roomUpdate':
            console.log('ROOM UPDATE', message)
            self.settings.locked = message.data.locked
            self.settings.maxPeers = message.data.maxPeers
            self.settings.requiresMedia = message.data.requiresMedia
            self.requestsToJoin = message.data.requests
            break
          case 'declined':
            self.state = RoomState.DECLINED
            break
          case 'onHold':
            self.state = RoomState.ON_HOLD
            break
          case 'reconnect':
          case 'joined': {
            console.log('JOINED', message)
            self.state = RoomState.CONNECTED
            console.log('create self stream, connect to each, with initiator')
            // create peers for each client in room
            if (!self.declinedPermissions) yield self.user.stream.getStream()
            self.user.setLastUsedRoom(self.id)
            if (!self.reconnected) {
              console.log('clearing peers')
              self.peers.clear()
            }
            message.data.clients.forEach((id: string) => {
              let peer
              if (self.peers.has(id)) {
                peer = self.peers.get(id)
                console.log('reacte peer from joined/reconnect?')
                peer.recreate()
              } else {
                console.log('creating peer', id)
                peer = Peer.create({ id })
                self.peers.set(id, peer)
              }
              if (self.user.hasStream) peer.addStream(self.user.stream.stream)
            })
            break
          }
          case 'startDisconnecting': {
            const peer = self.peers.get(message.data.id)
            if (peer) peer.disconnecting = true
            break
          }
          case 'signal':
            {
              const peer = self.peers.get(message.data.id)
              if (peer) peer.signal(message.data.signal)
            }
            break
          case 'peer': {
            console.log('PEER', message, self.peers.keys())
            if (!self.peers.has(message.data.id)) {
              console.log('creating peer', message.data)
              if (!self.declinedPermissions) yield self.user.stream.getStream()
              const peer = Peer.create({
                id: message.data.id,
                initiator: false
              })
              self.peers.set(message.data.id, peer)
              if (self.user.hasStream) peer.addStream(self.user.stream.stream)
            } else {
              console.log('recreate peer?')
              const peer = self.peers.get(message.data.id)
              peer.recreate()
              if (!peer.hasStream) {
                console.log('add stream to peer?')
                if (!self.declinedPermissions)
                  yield self.user.stream.getStream()
                if (self.user.hasStream) {
                  peer.addStream(self.user.stream.stream)
                }
              }
            }
            break
          }
          case 'existance':
            console.log('EXISTANCE', message)
            if (message.data && message.data[self.id]) {
              const data = message.data[self.id]
              self.exists = data.exists
              self.settings.locked = data.locked
              self.settings.maxPeers = data.maxPeers
              self.full = data.full
              self.settings.requiresMedia = data.requiresMedia
            } else {
              console.log('room does not exist?')
              self.exists = false
            }
            self.state = RoomState.REQUIRES_ACTION
            return
          default:
            console.warn('Unknown message', message)
            return
        }
      }),
      checkExistance() {
        getEnv<RoomEnv>(self).socket.send('checkRoomExistance', {
          id: self.id
        })
      },
      replaceTrack(
        oldTrack: MediaStreamTrack,
        track: MediaStreamTrack,
        stream: MediaStream
      ) {
        self.connectedPeers.forEach((peer) => {
          peer.replaceTrack(oldTrack, track, stream)
        })
      },
      addStream(stream: MediaStream) {
        console.log('PERS:', self.peers.size)
        self.connectedPeers.forEach((peer) => {
          peer.addStream(stream)
        })
      },
      removeStream(stream: MediaStream) {
        self.connectedPeers.forEach((peer) => {
          peer.removeStream(stream)
        })
      },
      addMessage(message: SnapshotIn<MessageInstance>) {
        self.messages.push(message)
      },
      removePeer(peer: PeerInstance) {
        this.addMessage({
          author: peer.id,
          type: MessageType.USER_LEFT,
          timestamp: new Date()
        })
        // We keep the peer for it's message references
      },
      sendPeerUpdate(data) {
        const json = JSON.stringify({
          type: 'peerUpdate',
          data: { ...data, id: self.user.id }
        })
        self.connectedPeers.forEach((peer) => {
          peer.sendMessage(json)
        })
      },
      sendMessage(message: string) {
        const data = {
          content: message.trim(),
          author: self.user.id,
          timestamp: new Date()
        }
        self.messages.push({ ...data, read: true })
        const json = JSON.stringify({
          type: 'message',
          data
        })
        self.connectedPeers.forEach((peer) => {
          peer.sendMessage(json)
        })
      },
      setHandleSize(size: number) {
        self.handleSize = size
        document.documentElement.style.setProperty('--handle-size', `${size}px`)
      },
      toggleMinimalMode() {
        self.minimalMode = !self.minimalMode
      },
      setPanelOpen(open: boolean) {
        self.panelOpen = open
      }
    }
  })
  .actions((self) => {
    let disposer: IDisposer
    return {
      afterCreate() {
        getEnv<RoomEnv>(self).socket.on('message', self.handleMessage)
        if (!self.reconnected) self.checkExistance()
        disposer = autorun(() => {
          self.layout.setCellCount(self.connectedPeers.length)
        })
      },
      beforeDestroy() {
        if (disposer) disposer()
        const socket = getEnv(self).socket
        socket.off('message', self.handleMessage)
      }
    }
  })

export interface RoomInstance extends Instance<typeof Room> {}
