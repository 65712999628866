/** @jsx jsx */
import { jsx, Grid } from 'theme-ui'
import { Formik, Field, Form } from 'formik'
import * as yup from 'yup'
import { Button } from '@components/Button'
import { Input } from '@components/forms/Input'

const initialValues = { message: '' }

const schema = yup.object().shape({
  message: yup.string().required()
})

export const MessageInputForm = ({ handleSubmit, updateScroll, inputRef }) => {
  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        handleSubmit(values.message)
        setSubmitting(false)
        updateScroll()
        resetForm()
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid
            gap={1}
            sx={{
              p: 2,
              gridTemplateColumns: '1fr min-content',
              borderTop: (theme) => `1px solid ${theme.colors.grays[500]}`,
              boxShadow: (theme) => `0 0 1em ${theme.colors.black}`,
              bg: 'grays.600'
            }}
          >
            <Field name="message">
              {({ field }) => <Input ref={inputRef} {...field} />}
            </Field>
            <Button type="submit" disabled={isSubmitting} sx={{ m: 0 }}>
              Send
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
