/** @jsx jsx */
import { jsx, Grid } from 'theme-ui'
import { CustomCheckboxContainer, CustomCheckboxInput } from '@reach/checkbox'
import '@reach/checkbox/styles.css'

interface ToggleProps {
  label: string
  onChange?: (value: boolean) => void
  checked?: boolean
}

export const Toggle = ({ label, onChange, checked = false }: ToggleProps) => (
  <Grid
    as="label"
    sx={{
      gridColumnGap: 2,
      alignItems: 'center',
      gridTemplateColumns: 'auto max-content'
    }}
  >
    {label}
    <CustomCheckboxContainer
      checked={checked}
      onChange={onChange ? (ev) => onChange(ev.target.checked) : undefined}
      sx={{
        borderRadius: '1em',
        width: '2em',
        height: '1.3em',
        bg: checked ? 'grays.500' : 'grays.600',
        transition: 'background 0.3s ease',
        border: (theme) =>
          `1px solid ${theme.colors.grays[checked ? 300 : 400]}`,
        '&:hover': {
          cursor: 'pointer',
          bg: 'grays.400',
          borderColor: checked ? 'grays.200' : 'grays.300',
          '& > span': {
            bg: checked ? 'pink' : 'grays.300'
          }
        },
        '&[data-reach-custom-checkbox-container]': {
          mr: 0
        }
      }}
    >
      <CustomCheckboxInput />
      <span
        aria-hidden
        sx={{
          position: 'absolute',
          top: '0.1em',
          left: checked ? 'auto' : '0.1em',
          right: checked ? '0.1em' : 'auto',
          borderRadius: '1em',
          width: '1em',
          height: '1em',
          bg: checked ? 'pink' : 'grays.400',
          transition: 'background 0.3s ease'
        }}
      />
    </CustomCheckboxContainer>
  </Grid>
)
