import { autorun, IReactionDisposer } from 'mobx'
import { types, getRoot, Instance } from 'mobx-state-tree'

import { LocalStream } from '@stores/models/LocalStream'
import { RoomInstance } from '@stores/models/Room'

export const User = types
  .model('User', {
    id: types.identifier,
    name: types.optional(types.string, 'Anonymous'),
    color: types.optional(types.string, 'blue'),
    lastUsedRoom: types.maybe(types.string),
    stream: types.optional(LocalStream, {}),
    lastAudioDevice: types.maybe(types.string),
    lastVideoDevice: types.maybe(types.string),
    mirroredStream: true
  })
  .views((self) => ({
    get hasStream() {
      return !!self.stream.stream
    },
    get data() {
      return {
        stream: {
          audioEnabled: self.stream.audioEnabled,
          videoEnabled: self.stream.videoEnabled,
          sharingScreen: self.stream.sharingScreen,
          aspectRatio: self.stream.aspectRatio
        },
        color: self.color,
        name: self.name
      }
    }
  }))
  .actions((self) => {
    let disposer: IReactionDisposer
    return {
      setMirroredStream(mirrored: boolean) {
        self.mirroredStream = mirrored
      },
      setName(name: string) {
        self.name = name.trim()
      },
      setLastUsedRoom(id: string) {
        self.lastUsedRoom = id
      },
      setLastAudioDevice(deviceId: string) {
        self.lastAudioDevice = deviceId
      },
      setLastVideoDevice(deviceId: string) {
        self.lastVideoDevice = deviceId
      },
      afterAttach() {
        disposer = autorun(() => {
          const room = getRoot<RoomInstance>(self)
          if (room) {
            room.sendPeerUpdate(self.data)
          }
        })
      },
      beforeDestroy() {
        if (disposer) disposer()
      }
    }
  })

export interface UserInstance extends Instance<typeof User> {}

export function LateUser() {
  return User
}
