/** @jsx jsx */
import { Grid, Text, jsx } from 'theme-ui'
import { observer } from 'mobx-react-lite'
import format from 'date-fns/format'

import { MessageInstance, MessageType } from '@stores/models/Message'

const SystemMessage = observer(({ message }: { message: MessageInstance }) => {
  return (
    <Grid
      gap={0}
      sx={{
        px: 3,
        py: 2,
        bg: 'grays.500',
        color: 'grays.200',
        borderRadius: 3,
        mb: 2
      }}
    >
      <Text variant="small">
        {format(message.timestamp, 'HH:mm')} –{' '}
        {message.type === MessageType.USER_JOINED
          ? `${message.author.name} joined`
          : `${message.author.name} left`}
      </Text>
    </Grid>
  )
})

export default SystemMessage
