/** @jsx jsx */
import * as React from 'react'
import { jsx, Grid } from 'theme-ui'
import { animated, useSpring, config } from 'react-spring'
import { useDrag } from 'react-use-gesture'
import { alpha } from '@theme-ui/color'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'
import FocusLock from 'react-focus-lock'
import { observer } from 'mobx-react-lite'

import { SegmentButton, SegmentButtonList } from '@components/SegmentControl'
import { UnreadMessageCounter } from '@components/UnreadMessageCounter'
import useWindowSize from '@hooks/useWindowSize'
import { useStore } from '@stores/useStore'

// const HANDLE_SIZE = 48
const SHADOW_OPACITY = 0.75
const HIDDEN_SHADOW_OPACITY = 0

const Panel = observer(
  ({
    children,
    onOpen,
    onClose,
    focusRef
  }: {
    children: React.ReactNode
    focusRef?: any
    onOpen?: () => void
    onClose?: () => void
  }) => {
    const { setHandleSize, panelOpen, setPanelOpen } = useStore()
    const windowSize = useWindowSize()
    const openRef = React.useRef(false)
    const draggingRef = React.useRef(false)
    const [measureRef, { height: HANDLE_SIZE }] = useMeasure({
      polyfill: ResizeObserver
    })
    const CONTENT_SIZE = (windowSize.height || window.innerHeight) * 0.9
    const TRAVEL_DISTANCE = CONTENT_SIZE - HANDLE_SIZE - 2

    const [{ y }, set] = useSpring(
      () => ({
        y: TRAVEL_DISTANCE
      }),
      []
    )

    const open = (canceled?: boolean) => {
      // when cancel is true, it means that the user passed the upwards threshold
      // so we change the spring config to create a nice wobbly effect
      set({
        y: 0,
        config: canceled ? config.wobbly : config.stiff
      })
      openRef.current = true
      setPanelOpen(true)
      if (!canceled && focusRef?.current) focusRef.current.focus()
      if (onOpen) onOpen()
    }

    const close = (velocity = 0) => {
      set({
        y: TRAVEL_DISTANCE,
        config: { ...config.stiff, velocity }
      })
      openRef.current = false
      setPanelOpen(false)
      if (focusRef?.current) focusRef.current.blur()
      if (onClose) onClose()
    }

    const bind = useDrag(
      ({
        first,
        last,
        down,
        vxvy: [, vy],
        movement: [, my],
        cancel,
        canceled
      }) => {
        if (first) draggingRef.current = true
        if (last) draggingRef.current = false
        if (my < -30 && cancel) {
          cancel()
        }

        if (last) {
          if (my > TRAVEL_DISTANCE * 0.65 || vy > 0.5) {
            close(vy)
          } else {
            open(canceled)
          }
        } else {
          set({
            y: my,
            immediate: down,
            config: config.stiff
          })
        }
      },
      {
        initial: () => [0, y.get()]
      }
    )

    React.useEffect(() => {
      if (openRef.current) {
        open()
      } else {
        close()
      }
    }, [windowSize.height])

    React.useEffect(() => {
      setHandleSize(HANDLE_SIZE)
      if (openRef.current) {
        open()
      } else {
        close()
      }
    }, [HANDLE_SIZE])

    React.useEffect(() => {
      if (panelOpen && openRef.current === false) {
        open()
      }
      if (!panelOpen && openRef.current === true) {
        close()
      }
    }, [panelOpen])

    const shadowSize = y.to(
      [0, TRAVEL_DISTANCE],
      [SHADOW_OPACITY, HIDDEN_SHADOW_OPACITY],
      'clamp'
    )

    return (
      <animated.div
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          userSelect: 'none',
          touchAction: 'none'
        }}
        style={{
          y,
          boxShadow: shadowSize.to(
            (opacity) => `0 0 80px 0 rgba(0,0,0,${opacity})`
          )
        }}
      >
        <FocusLock disabled={!panelOpen}>
          <animated.div
            sx={{
              bg: 'grays.600',
              border: (theme) => `1px solid ${theme.colors.border}`,
              overflow: 'hidden',
              position: 'relative',
              height: CONTENT_SIZE
            }}
            onKeyDown={(ev) => {
              if (ev.key === 'Escape') close()
            }}
          >
            {children}
            <Grid
              sx={{
                top: 0,
                p: 1,
                gridGap: 1,
                width: '100%',
                position: 'absolute',
                pointerEvents: 'all',
                alignItems: 'center',
                justifyContent: 'center',
                bg: alpha('grays.600', 0.7),
                backdropFilter: 'blur(8px)',
                boxShadow: (theme) => `0 0 0 1px ${theme.colors.border}`
              }}
              ref={measureRef}
              {...bind()}
            >
              <div
                sx={{
                  justifySelf: 'center',
                  height: 1,
                  width: '50%',
                  bg: 'grays.200'
                }}
              />
              <SegmentButtonList
                sx={{ minWidth: 320 }}
                onClick={() => {
                  if (!panelOpen) {
                    open()
                  }
                }}
              >
                <SegmentButton index={0}>
                  <UnreadMessageCounter
                    sx={{ position: 'absolute', left: 2 }}
                  />
                  Chat
                </SegmentButton>
                <SegmentButton index={1}>Settings</SegmentButton>
              </SegmentButtonList>
            </Grid>
          </animated.div>
        </FocusLock>
      </animated.div>
    )
  }
)

export default Panel
