import { types, Instance } from 'mobx-state-tree'

export const Layout = types
  .model('Layout', {
    width: typeof window !== 'undefined' ? window.innerWidth : 100,
    height: typeof window !== 'undefined' ? window.innerHeight : 100,
    targetRatio: 3 / 2,
    cellCount: 1,
    margin: 10
  })
  .views((self) => ({
    get windowRatio() {
      return self.width / self.height
    },
    w(number: number) {
      return self.width * number
    },
    h(number: number) {
      return self.height * number
    },
    get positions() {
      const w = this.w
      const h = this.h
      const positions = [
        [
          {
            top: 0,
            left: 0,
            width: w(1),
            height: h(1)
          }
        ],
        [
          {
            top: 0,
            left: 0,
            width: w(0.5),
            height: h(1)
          },
          {
            top: 0,
            left: w(0.5),
            width: w(0.5),
            height: h(1)
          }
        ],
        [
          {
            top: 0,
            left: 0,
            width: w(0.5),
            height: h(0.5)
          },
          {
            top: 0,
            left: w(0.5),
            width: w(0.5),
            height: h(0.5)
          },
          {
            top: h(0.5),
            left: 0,
            width: w(1),
            height: h(0.5)
          }
        ],
        [
          {
            top: 0,
            left: 0,
            width: w(0.5),
            height: h(0.5)
          },
          {
            top: 0,
            left: w(0.5),
            width: w(0.5),
            height: h(0.5)
          },
          {
            top: h(0.5),
            left: 0,
            width: w(0.5),
            height: h(0.5)
          },
          {
            top: h(0.5),
            left: w(0.5),
            width: w(0.5),
            height: h(0.5)
          }
        ],
        [
          {
            top: 0,
            left: 0,
            width: w(1 / 3),
            height: h(0.5)
          },
          {
            top: 0,
            left: w(1 / 3),
            width: w(1 / 3),
            height: h(0.5)
          },
          {
            top: 0,
            left: w(2 / 3),
            width: w(1 / 3),
            height: h(0.5)
          },
          {
            top: h(0.5),
            left: 0,
            width: w(0.5),
            height: h(0.5)
          },
          {
            top: h(0.5),
            left: w(0.5),
            width: w(0.5),
            height: h(0.5)
          }
        ],
        [
          {
            top: 0,
            left: 0,
            width: w(1 / 3),
            height: h(0.5)
          },
          {
            top: 0,
            left: w(1 / 3),
            width: w(1 / 3),
            height: h(0.5)
          },
          {
            top: 0,
            left: w(2 / 3),
            width: w(1 / 3),
            height: h(0.5)
          },
          {
            top: h(0.5),
            left: 0,
            width: w(1 / 3),
            height: h(0.5)
          },
          {
            top: h(0.5),
            left: w(1 / 3),
            width: w(1 / 3),
            height: h(0.5)
          },
          {
            top: h(0.5),
            left: w(2 / 3),
            width: w(1 / 3),
            height: h(0.5)
          }
        ]
      ]
      positions.forEach((group) =>
        group.forEach((position) => {
          position.top += self.margin
          position.left += self.margin
          position.width -= self.margin + self.margin
          position.height -= self.margin + self.margin
        })
      )
      return positions[self.cellCount - 1]
    }
  }))
  .actions((self) => {
    return {
      setCellCount(count: number) {
        self.cellCount = Math.min(Math.max(1, count), 9)
      },
      updateSize() {
        self.width = window.innerWidth
        self.height = window.innerHeight
      },
      afterCreate() {
        window.addEventListener('resize', this.updateSize)
      },
      beforeDestroy() {
        window.removeEventListener('resize', this.updateSize)
      }
    }
  })

export interface LayoutInstance extends Instance<typeof Layout> {}
