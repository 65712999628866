/** @jsx jsx */
import * as React from 'react'
import { Grid, Text, jsx } from 'theme-ui'
import format from 'date-fns/format'
import { observer } from 'mobx-react-lite'
import { alpha } from '@theme-ui/color'

import { MessageInstance } from '@stores/models/Message'
import useIntersect from '@hooks/useIntersect'

interface MessageProps {
  showTime: boolean
  message: MessageInstance
  last: boolean
  showUser: boolean
}

const Message = observer<MessageProps>(
  ({ showTime, message, last, showUser }) => {
    const [messageRef, entry, disconnect] = useIntersect({
      threshold: 0.5
    })

    React.useEffect(() => {
      if (entry && entry.isIntersecting) {
        message.markAsRead()
        disconnect()
      }
    }, [entry])

    return (
      <Grid
        gap={0}
        sx={{
          px: 3,
          py: 2,
          bg: message.isOwner ? 'pink' : 'black',
          color: 'white',
          borderRadius: 4,
          borderBottomRightRadius: last && message.isOwner ? 0 : 4,
          borderBottomLeftRadius: last && !message.isOwner ? 0 : 4,
          gridTemplateRows: 'min-content max-content',
          mb: '1px'
        }}
        ref={messageRef}
      >
        {showTime && (
          <Text
            variant="small"
            sx={{
              color: message.isOwner ? alpha('grays.600', 0.65) : 'grays.300'
            }}
          >
            {showUser && `${message.author.name} – `}
            {format(message.timestamp, 'HH:mm')}
          </Text>
        )}
        <Text>{message.content}</Text>
      </Grid>
    )
  }
)

export default Message
