import { types, getRoot, getEnv, flow, IDisposer } from 'mobx-state-tree'
import { autorun } from 'mobx'

import { MediaConstraints, ScreenConstraints } from '@stores/models/Constraints'
import { RoomInstance } from '@stores/models/Room'
import { PeerStream } from '@stores/models/PeerStream'

const handleMediaStreamError = (ev: MediaStreamError) => {
  switch (ev.name) {
    case 'NotFoundError':
      return 'Unable to open your call because no camera and/or microphone were found.'
    case 'SecurityError':
    case 'PermissionDeniedError':
      return 'Permission denied to open your camera and/or microphone.'
    default:
      return 'Error opening your camera and/or microphone: ' + ev.message
  }
}

export const LocalStream = PeerStream.named('LocalStream')
  .props({
    cameraConstraints: types.optional(MediaConstraints, {}),
    screenConstraints: types.optional(ScreenConstraints, {}),
    currentVideoDevice: types.maybe(types.string),
    currentAudioDevice: types.maybe(types.string),
    hasScreenSharing: types.optional(
      types.boolean,
      typeof window !== 'undefined' && !!navigator.mediaDevices.getDisplayMedia
    )
  })
  .volatile((): {
    devices: MediaDeviceInfo[]
    stream: MediaStream | undefined
  } => {
    return {
      devices: [],
      stream: undefined
    }
  })
  .views((self) => ({
    get hasMultipleDevices() {
      return self.devices.length > 1
    },
    get audioDevices() {
      return self.devices.filter((d) => d.kind === 'audioinput')
    },
    get videoDevices() {
      return self.devices.filter((d) => d.kind === 'videoinput')
    },
    get hasMultipleAudioDevices() {
      return this.audioDevices.length > 1
    },
    get hasMultipleVideoDevices() {
      return this.videoDevices.length > 1
    }
  }))
  .actions((self) => ({
    updateDevices() {
      if (self.stream) {
        const user = getEnv(self).user
        const videoTracks = self.stream.getVideoTracks()
        if (videoTracks.length > 0) {
          const videoTrackId = videoTracks[0].getSettings().deviceId
          if (videoTrackId) {
            self.currentVideoDevice = videoTrackId
            user.setLastVideoDevice(videoTrackId)
          }
        }
        const audioTracks = self.stream.getAudioTracks()
        if (audioTracks.length > 0) {
          const audioTrackId = audioTracks[0].getSettings().deviceId
          if (audioTrackId) {
            user.setLastAudioDevice(audioTrackId)
            self.currentAudioDevice = audioTrackId
          }
        }
      }
    },
    getDevices: flow(function* getDevices() {
      try {
        self.devices = yield navigator.mediaDevices.enumerateDevices()
        return self.devices
      } catch (e) {
        console.error(e.name + ': ' + e.message)
        return []
      }
    }),
    getCamera: flow(function* getCamera() {
      try {
        if (self.stream) {
          const oldVideoTrack = self.stream.getVideoTracks()[0]
          const oldAudioTrack = self.stream.getAudioTracks()[0]
          let requestAudio = false
          let requestVideo = false
          if (self.cameraConstraints.audio.deviceId !== self.currentAudioDevice)
            requestAudio = true
          if (
            self.sharingScreen ||
            self.cameraConstraints.video.deviceId !== self.currentVideoDevice
          )
            requestVideo = true
          if (requestAudio || requestVideo) {
            console.log({
              video: requestVideo
                ? self.cameraConstraints.video.toJSON()
                : false,
              audio: requestAudio
                ? self.cameraConstraints.audio.toJSON()
                : false
            })
            const newStream = yield navigator.mediaDevices.getUserMedia({
              video: requestVideo ? self.cameraConstraints.video : false,
              audio: requestAudio ? self.cameraConstraints.audio : false
            })

            if (requestVideo) {
              const newVideoTrack = newStream.getVideoTracks()[0]
              console.log(newVideoTrack.getSettings(), self.currentVideoDevice)
              oldVideoTrack.enabled = false
              oldVideoTrack.stop()
              self.stream.removeTrack(oldVideoTrack)
              self.stream.addTrack(newVideoTrack)
              getRoot<RoomInstance>(self).replaceTrack(
                oldVideoTrack,
                newVideoTrack,
                self.stream
              )
            }
            if (requestAudio) {
              const newAudioTrack = newStream.getAudioTracks()[0]
              if (
                self.currentAudioDevice !== newAudioTrack.getSettings().deviceId
              ) {
                oldAudioTrack.enabled = false
                oldAudioTrack.stop()
                self.stream.removeTrack(oldAudioTrack)
                self.stream.addTrack(newAudioTrack)
                getRoot<RoomInstance>(self).replaceTrack(
                  oldAudioTrack,
                  newAudioTrack,
                  self.stream
                )
              } else {
                newAudioTrack.enabled = false
                newAudioTrack.stop()
              }
            }
          }
        } else {
          console.log(self.cameraConstraints.toJSON())
          self.stream = yield navigator.mediaDevices.getUserMedia(
            self.cameraConstraints
          )
        }

        self.videoEnabled = true
        self.audioEnabled = true
        self.sharingScreen = false
        self.updateDevices()

        return self.stream
      } catch (e) {
        throw new Error(handleMediaStreamError(e))
      }
    }),
    getScreen: flow(function* getScreen() {
      try {
        if (self.stream) {
          const requestAudio = false
          const requestVideo = true
          if (requestAudio || requestVideo) {
            const newStream = yield navigator.mediaDevices.getDisplayMedia({
              video: requestVideo ? self.screenConstraints.video : false,
              audio: requestAudio ? self.screenConstraints.audio : false
            })

            if (requestVideo) {
              const oldVideoTrack = self.stream.getVideoTracks()[0]
              const newVideoTrack = newStream.getVideoTracks()[0]
              oldVideoTrack.enabled = false
              self.stream.removeTrack(oldVideoTrack)
              self.stream.addTrack(newVideoTrack)
              console.log(newVideoTrack.getSettings())
              getRoot<RoomInstance>(self).replaceTrack(
                oldVideoTrack,
                newVideoTrack,
                self.stream
              )
            }
            if (requestAudio) {
              const oldAudioTrack = self.stream.getAudioTracks()[0]
              const newAudioTrack = newStream.getAudioTracks()[0]
              oldAudioTrack.enabled = false
              self.stream.removeTrack(oldAudioTrack)
              self.stream.addTrack(newAudioTrack)
              getRoot<RoomInstance>(self).replaceTrack(
                oldAudioTrack,
                newAudioTrack,
                self.stream
              )
            }
          }
        } else {
          self.stream = yield navigator.mediaDevices.getDisplayMedia(
            self.cameraConstraints
          )
        }

        self.videoEnabled = true
        self.audioEnabled = self.stream
          ? self.stream.getAudioTracks().length > 0
          : false
        self.sharingScreen = true

        self.updateDevices()

        return self.stream
      } catch (e) {
        throw new Error(handleMediaStreamError(e))
      }
    })
  }))
  .actions((self) => {
    return {
      getStream: flow(function* getStream() {
        if (self.stream) return self.stream
        const s: MediaStream | undefined = self.sharingScreen
          ? yield self.getScreen()
          : yield self.getCamera()
        if (!self.devices.length) yield self.getDevices()
        console.log('got devices', self.devices)
        return s
      }),
      switchCamera: flow(function* switchCamera() {
        if (self.hasMultipleVideoDevices) {
          if (self.stream) {
            const track = self.stream.getVideoTracks()[0]
            const c = track.getSettings()
            const newDevice = self.videoDevices.filter(
              (device) => device.deviceId !== c.deviceId
            )[0]
            self.cameraConstraints.video.setDeviceId(newDevice.deviceId, true)
          }
          return yield self.getCamera()
        }
        return self.getStream()
      }),
      replaceStream: flow(function* replaceStream(useScreen: boolean) {
        return useScreen ? yield self.getScreen() : yield self.getCamera()
      }),
      setVideoDevice: flow(function* setVideoDevice(deviceId: string) {
        if (self.stream) {
          const track = self.stream.getVideoTracks()[0]
          const c = track.getSettings()
          if (c.deviceId !== deviceId) {
            self.cameraConstraints.video.setDeviceId(deviceId, true)
            return yield self.replaceStream()
          }
        } else {
          self.cameraConstraints.video.setDeviceId(deviceId, true)
          return yield self.replaceStream()
        }
        return self.stream
      }),
      setAudioDevice: flow(function* setAudioDevice(deviceId: string) {
        if (self.stream) {
          const track = self.stream.getAudioTracks()[0]
          const c = track.getSettings()
          if (c.deviceId !== deviceId) {
            self.cameraConstraints.audio.setDeviceId(deviceId, true)
            self.screenConstraints.audio.setDeviceId(deviceId, true)
            return yield self.replaceStream()
          }
        } else {
          self.cameraConstraints.audio.setDeviceId(deviceId, true)
          self.screenConstraints.audio.setDeviceId(deviceId, true)
          return yield self.replaceStream()
        }
        return self.stream
      }),
      setVideoEnabled(enabled: boolean) {
        self.stream
          ?.getVideoTracks()
          .forEach((track) => (track.enabled = enabled))
        self.videoEnabled = enabled
      },
      setAudioEnabled(enabled: boolean) {
        self.stream
          ?.getAudioTracks()
          .forEach((track) => (track.enabled = enabled))
        self.audioEnabled = enabled
      },
      toggleVideo() {
        this.setVideoEnabled(!self.videoEnabled)
      },
      toggleAudio() {
        this.setAudioEnabled(!self.audioEnabled)
      }
    }
  })
  .actions((self) => {
    let disposer: IDisposer
    return {
      afterCreate() {
        disposer = autorun(() => {
          const videoTrack = self.stream?.getVideoTracks()[0]
          const settings = videoTrack?.getSettings()
          if (settings) {
            self.aspectRatio = (settings.width || 4) / (settings.height || 3)
          }
        })
      },
      beforeDestroy() {
        if (disposer) disposer()
        self.destroyStream()
      }
    }
  })
