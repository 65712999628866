import * as React from 'react'

export default ({
  root = null,
  rootMargin,
  threshold = 0
}: IntersectionObserverInit): [
  React.Dispatch<React.SetStateAction<Element | undefined>>,
  IntersectionObserverEntry | undefined,
  () => void
] => {
  const [entry, updateEntry] = React.useState<IntersectionObserverEntry>()
  const [node, setNode] = React.useState<Element>()

  const observer = React.useRef(
    new window.IntersectionObserver(([entry]) => updateEntry(entry), {
      root,
      rootMargin,
      threshold
    })
  )

  const disconnect = () => {
    observer.current.disconnect()
  }

  React.useEffect(() => {
    observer.current.disconnect()
    if (node) observer.current.observe(node)
    return () => observer.current.disconnect()
  }, [node])

  return [setNode, entry, disconnect]
}
