/** @jsx jsx */
import * as React from 'react'
import { Grid, Heading, Text, jsx, useColorMode } from 'theme-ui'
import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'

import { SEO } from '@components/SEO'
import Logo from '@components/Logo'
import { Button } from '@components/Button'
import RoomNameForm, { generateRandomName } from '@components/forms/RoomName'

export const Home = (props: RouteComponentProps) => {
  const [, setColorMode] = useColorMode()

  React.useEffect(() => {
    setColorMode('default')
  }, [])

  return (
    <React.Fragment>
      <SEO title="Home" />
      <Grid sx={{ justifyContent: 'center', gridRowGap: 6, pb: 6 }}>
        <Grid
          sx={{
            textAlign: 'center',
            justifyContent: 'center',
            marginTop: '-10vmin'
          }}
        >
          <Logo sx={{ width: '50vmin', mx: 'auto' }} />
          <Text variant="caps" sx={{ fontSize: '2.75vmin' }}>
            Babble without a fuss
          </Text>
        </Grid>
        <RoomNameForm />
        <Grid
          sx={{
            bg: 'white',
            p: 3,
            borderBottom: '3px solid black',
            maxWidth: '28em',
            mx: 2
          }}
        >
          <Heading>No hassle video chats</Heading>
          <Text>
            Blablabla gives you easy-peasy video chats in the browser — no
            downloads &amp; no logins necessary.
          </Text>
          <Grid
            as="ul"
            sx={{
              listStyleType: 'none',
              m: 0,
              gridRowGap: 2
            }}
          >
            <li>→ Easy-to-read chat room links</li>
            <li>→ Desktop &amp; mobile access</li>
            <li>→ Secure conversations</li>
            <li>→ Screen sharing</li>
            <li>→ Lockable rooms</li>
          </Grid>
          <Button
            variant="secondary"
            onClick={() =>
              navigate(`/${generateRandomName()}`, {
                state: { action: 'create' }
              })
            }
            sx={{ justifySelf: 'center', my: 3 }}
          >
            Create a random room now→
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
